import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { AmountNumberPadComponent } from '../amount-number-pad/amount-number-pad.component';
import {
  ProductInputChangeEvent,
  QuantityType
} from "../product-amount-grid/product-amount-grid.component";

@Component({
  selector: 'prf-amount-number-pad-modal',
  standalone: true,
  imports: [CommonModule, IonicModule, AmountNumberPadComponent],
  templateUrl: './amount-number-pad-modal.component.html',
  styleUrls: ['./amount-number-pad-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AmountNumberPadModalComponent {
  @Input({ required: true })
  isOpen!: boolean;

  @Input({ required: true })
  item!: any;

  @Input({ required: true })
  quantityType!: QuantityType;

  @Output()
  didDismiss = new EventEmitter<any>();

  @Output()
  numberChange = new EventEmitter<ProductInputChangeEvent>();

  @Output()
  confirm = new EventEmitter<void>();

  @Output()
  next = new EventEmitter<void>();

  onAmountNumberPadNumberChange(event: ProductInputChangeEvent) {
    this.numberChange.emit(event);
  }

  onConfirm() {
    this.confirm.emit();
  }

  onNext() {
    this.next.emit();
  }

  onMinimizeAmountNumberPad() {
    this.isOpen = false;
  }
}
