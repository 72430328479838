<prf-content-toolbar-header title="{{viewTitle ?? ''}}"
                            subTitle="{{viewSubTitle ?? ''}}"
/>

<ion-content>
  <ion-header>
    <ion-toolbar>
      <ion-segment [value]="MOBILE_ROUTE_PATHS.MARKET_INFO"
                   (ionChange)="onNavTabChange($event)">
        <ion-segment-button value="market-info">
          <ion-icon name="storefront"></ion-icon>
        </ion-segment-button>
        <ion-segment-button [value]="MOBILE_ROUTE_PATHS.DELIVERY_SLIP"
                            [disabled]="isDelivered"
        >
          <ion-icon name="receipt-outline"></ion-icon>
        </ion-segment-button>
        <ion-segment-button [value]="MOBILE_ROUTE_PATHS.PHOTOS">
          <ion-icon name="camera-outline"></ion-icon>
        </ion-segment-button>
        <!--        DEACTIVATED FOR MVP -->
        <!--        <ion-segment-button [value]="MOBILE_ROUTE_PATHS.MESSAGES">-->
        <!--          &lt;!&ndash;          <ion-label>Nachrichten</ion-label>&ndash;&gt;-->
        <!--          &lt;!&ndash;          <ion-icon name="mail"></ion-icon>&ndash;&gt;-->
        <!--          <ion-icon name="mail-outline"></ion-icon>-->
        <!--        </ion-segment-button>-->
      </ion-segment>

    </ion-toolbar>
  </ion-header>

  <ion-router-outlet style="top: 56px;" [animated]="false" />

</ion-content>
