import { Component, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DeliveryMarketsTourPageService } from './delivery-markets-tour-page.service';
import dayjs from 'dayjs';
import { MOBILE_ROUTE_PATHS } from '../../app.routes';
import { ContentToolbarHeaderComponent } from '../../shared/components/content-toolbar-header/content-toolbar-header.component';
import { CommonModule, registerLocaleData } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { IDriverDelivery } from '@prf/shared/domain';
import 'dayjs/locale/de';
import localeDe from '@angular/common/locales/de';
import { DeliveriesDataService } from '../../shared/services/data/deliveries-data.service';
import { forkJoin, from, timer } from 'rxjs';

// TODO: Refactor: Init DE locale globally.
registerLocaleData(localeDe, 'de');

@Component({
  selector: 'prf-delivery-markets-tour-page',
  templateUrl: './delivery-markets-tour.page.component.html',
  styleUrls: ['./delivery-markets-tour.page.component.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, ContentToolbarHeaderComponent],
})
export class DeliveryMarketsTourPageComponent {
  formattedHeaderDate = '';
  refreshInProgress = false;
  private activatedRoute = inject(ActivatedRoute);
  private router = inject(Router);
  private deliveriesDataService = inject(DeliveriesDataService);
  protected deliveryMarketsTourPageService = inject(DeliveryMarketsTourPageService);

  constructor() {}

  ionViewWillEnter(): void {
    console.log('ionViewWillEnter - MARKETS TOUR PAGE');
    const paramDate = this.activatedRoute.snapshot.paramMap.get('date') as string;
    this.formattedHeaderDate = this.formatHeaderDate(paramDate);

    const routeDate = dayjs(paramDate);
    if (routeDate.isValid()) {
      this.deliveryMarketsTourPageService.setDate(paramDate);
    } else {
      throw new Error('Invalid route date: ' + paramDate);
      // TODO: Show error toast.
    }

    if (navigator.onLine) {
      console.warn('ionViewWillEnter - APP HAS INTERNET');
      this.checkForPendingDeliveriesToBeSynced();
    } else {
      console.warn('ionViewWillEnter - APP HAS ___NO___ INTERNET');
    }

    setTimeout(() => {
      if (navigator.onLine) {
        console.warn('ionViewWillEnter - photos after 5s: APP HAS INTERNET');
        this.checkForPendingPhotosToBeSynced();
      } else {
        console.warn('ionViewWillEnter - photos after 5s: APP HAS ___NO___ INTERNET');
      }
    }, 5000);
  }

  checkForPendingDeliveriesToBeSynced(): void {
    this.deliveriesDataService.checkForPendingDeliveriesToBeSynced();
  }

  checkForPendingPhotosToBeSynced(): void {
    this.deliveriesDataService.checkForPendingPhotosToBeSynced();
  }

  onClickDeliveryItem(delivery: IDriverDelivery, isToday: boolean): void {
    // Drivers can only look at delivery details of today's tour.
    if (!isToday) {
      return;
    }

    this.router.navigate([MOBILE_ROUTE_PATHS.DELIVERY_DETAILS + '/' + delivery.id]);
  }

  formatHeaderDate(date: string | Date | null): string {
    if (!date) {
      return '';
    }
    return dayjs(date).format('dddd, DD.MM.YYYY');
  }

  // MERGE with deliveries week page... extract shared method and pass event
  async handleRefreshDeliveries(event: any): Promise<void> {
    // TODO: check for internet connection ON
    // TODO: show msg, if no internet available

    // TODO: set refresh in progress indicator -- probably globally via service...
    // -> show ion-skeletons while refresh is in progress

    // TODO: add same refresher to delivery-markets-tour
    // extract into own standalone comp?

    this.refreshInProgress = true;

    if (navigator.onLine) {
      forkJoin([
        from(this.deliveriesDataService.pullServerDeliveriesForMyCurrentWeek()),
        from(this.deliveriesDataService.checkForPendingPhotosToBeSynced()),
        // from(this.deliveriesDataService.checkForPendingDeliveriesToBeSynced()),
        timer(1000), // Show refresh indication for at least 1 second (even if req is quicker).
      ]).subscribe(() => {
        event.target.complete();
        this.refreshInProgress = false;
      });
    } else {
      event.target.complete();
      this.refreshInProgress = false;
      setTimeout(() => {
        window.alert("Aktualisieren nicht möglich. Aktuell liegt keine Internetverbindung vor.")
      }, 250);
    }
  }
}
