import * as Types from './_types.generated';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type GetAvailableVehiclesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetAvailableVehiclesQuery = { __typename?: 'Query', availableDriverVehicles: Array<{ __typename?: 'VehicleEntity', id: number, licensePlate: string, vehicleModel: string }> };

export const GetAvailableVehiclesDocument = gql`
    query GetAvailableVehicles {
  availableDriverVehicles {
    id
    licensePlate
    vehicleModel
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetAvailableVehiclesService extends Apollo.Query<GetAvailableVehiclesQuery, GetAvailableVehiclesQueryVariables> {
    override document = GetAvailableVehiclesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }