<ng-container *ngIf="label">
  <ion-label position="floating">{{label}}</ion-label>
</ng-container>
<ion-input [(ngModel)]="value"
           [type]="type"
           [placeholder]="placeholder"
           [required]="required"
           [disabled]="disabled"
           [readonly]="readonly"
           (ionFocus)="hasFocus.emit()"
           [class.has-prf-focus]="isFocussed"
           [maxlength]="maxlength"
           fill="solid"
           class="prf-theme"
           label-placement="floating">
</ion-input>

