import * as Types from './_types.generated';

import { gql } from 'apollo-angular';
export type AddressFieldsFragment = { __typename?: 'AddressEmbeddable', street: string, houseNumber: string, postalCode: string, city: string, country: string };

export const AddressFieldsFragmentDoc = gql`
    fragment AddressFields on AddressEmbeddable {
  street
  houseNumber
  postalCode
  city
  country
}
    `;