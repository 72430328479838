import { IEntity } from './entity.interface';
import { IProduct } from "./product.interface";

export enum DeliveryType {
  PRIMARY = 'PRIMARY',
  RESUPPLY = 'RESUPPLY',
}

export enum DeliveryStatus {
  CREATED = 'CREATED',
  READY_TO_DELIVER = 'READY_TO_DELIVER',

  DELIVERED = 'DELIVERED',

  // -> Abgeschlossen wird zu fakturiert
  COMPLETED = 'COMPLETED',
}

// TODO: Add typing for: IDelivery.property + Postfix "Label". driverId vs driver?
export interface DataTableDelivery extends IDelivery {
  deliveryDateLabel: string;
  driverLabel: string;
  marketNameLabel: string;
  marketDeliveryAddressLabel: string;
}

export interface IDelivery extends IEntity {
  marketId?: number | null;
  driverId?: number | null;
  vehicleId?: number | null;
  deliverySlipNumber: string | null;
  hasPostDeliveryCorrections: boolean;
  deliverySlipEmailSent: boolean;

  isActive: boolean;
  deliveryDate: Date | string;                 // TODO: Check format. Make it one-type.
  deliveryDeliveredDate: Date | string | null; // TODO: Check format. Make it one-type.
  type: DeliveryType;
  status: DeliveryStatus;
  driverNote?: string;
  deliveryProducts?: any | null; // TODO: Proper typing, get rid of any[]. Collision with server entity:   deliveryProducts = new Collection<DeliveryProductEntity>(this);
  // deliveryProducts?: IDeliveryProduct[] | null;

  photos?: any;

  processedDelivery?: IProcessedDelivery | null; // TODO: Proper typing.
}

export interface IDriverDelivery extends Pick<IDelivery, 'id' | 'marketId' | 'deliveryDate' | 'deliverySlipNumber' | 'deliveryProducts' | 'type' | 'status' | 'driverNote' | 'vehicleId'> {
  marketName: string;
  marketAddress: string;
  marketPostalCode: string;
  marketPhone: string;
  marketDeliverySlipEmail: string;
  isDelivered: boolean;
}

export interface DeliveryTour {
  date: string;
  // calendarWeek: number; // TODO
  numMarkets: number;
  isToday: boolean;
  deliveries: IDriverDelivery[];
}

export interface IDeliveryProduct extends IEntity {
  deliveryId: number;
  productId: number;
  targetQuantity: number | null;
  actualQuantity?: number | null;
  returnQuantity?: number | null;
}

export interface IDriverDeliveryProduct extends IDeliveryProduct {
  product: IProduct;
}

export interface IProcessedDelivery {
  // deliveryId: number; // Also acts as main dexie table id
  messageFromMarket: string;
  signatureName: string;
  // signatureImage: string; // base64
  deliveredAtMarketDate: Date;
  // deliveryProducts: object[]; // TODO: Type
  // syncStatus: LocalDeliveryDataSyncStatus;
}
