import { inject, Injectable } from '@angular/core';
import {
  AppIndexedDb,
  DeliveryPhoto,
  LocalDeliveryDataSyncStatus,
  ProcessedDelivery,
} from '../db/app-indexed-db.service';
import { MyDeliveriesForCurrentWeekService } from '../../../graphql/deliveries-week-operations.generated';
import { catchError, from, map, Observable, of, tap } from 'rxjs';
import { IAddress, IDeliveryProduct, IDriverDelivery } from '@prf/shared/domain';
import { DeliveryStatus, DeliveryType } from '../../../graphql/_types.generated';
import {
  FinishDriverDeliveryService,
  SubmitDeliveryPhotoService,
} from '../../../graphql/delivery-operations.generated';

@Injectable({
  providedIn: 'root',
})
export class DeliveriesDataService {
  private appIndexedDb = inject(AppIndexedDb);
  private myDeliveriesForCurrentWeekService = inject(MyDeliveriesForCurrentWeekService);

  // TODO: post-fix all generated gql services with GqlService
  private finishDriverDeliveryService = inject(FinishDriverDeliveryService);
  private submitDeliveryPhotoService = inject(SubmitDeliveryPhotoService);

  public deliveries$: Observable<IDriverDelivery[]> = from(this.appIndexedDb.deliveries$);

  photoUploadRetries = new Map<number, number>();

  constructor() {
    this.pullServerDeliveriesForMyCurrentWeek(); // Note: this is async
  }

  private fetchServerDeliveriesForMyCurrentWeek(): Observable<IDriverDelivery[]> {
    return this.myDeliveriesForCurrentWeekService.fetch().pipe(
      map((queryResult) => {
        const deliveryEntities = queryResult?.data?.myDeliveriesForCurrentWeek;
        // TODO: check typing for deliveryEntity
        return deliveryEntities?.map((deliveryEntity: any): IDriverDelivery => {
          return {
            id: deliveryEntity.id,
            deliveryDate: deliveryEntity.deliveryDate,
            deliverySlipNumber: deliveryEntity.deliverySlipNumber,
            deliveryProducts: deliveryEntity.deliveryProducts.map((dP: IDeliveryProduct) => ({
              ...dP,
              actualQuantity: dP.actualQuantity ?? dP.targetQuantity,
              returnQuantity: dP.returnQuantity ?? 0,
            })),
            driverNote: deliveryEntity.driverNote,
            marketId: deliveryEntity.market!.id,
            marketName: deliveryEntity.market!.marketName,
            marketAddress: this.formatAddress(deliveryEntity.market!.deliveryAddress),
            marketPostalCode: deliveryEntity.market.deliveryAddress.postalCode,

            // Note. this is a IDriverDelivery-only flag. It can get overridden by adding a locally stored ProcessedDelivery.
            isDelivered:
              (deliveryEntity.status as DeliveryStatus) === 'DELIVERED' ||
              (deliveryEntity.status as DeliveryStatus) === 'COMPLETED',

            marketPhone: deliveryEntity.market.marketPhone || '- Nicht vorhanden -',
            marketDeliverySlipEmail: deliveryEntity.market.deliverySlipEmail,
            status: deliveryEntity.status as DeliveryStatus,
            type: deliveryEntity.type as DeliveryType,
            vehicleId: null,
          };
        });
      }),
    );
  }

  async pullServerDeliveriesForMyCurrentWeek() {
    console.log('--> pullServerDeliveriesForMyCurrentWeek -');
    const processedDeliveries = await this.appIndexedDb.allProcessedDeliveries();
    const processedDeliveriesSet = new Set(processedDeliveries.map((pd) => pd.deliveryId));

    // TODO: Check if this needs to be unsubscribed from, ie pipe(take(1))
    this.fetchServerDeliveriesForMyCurrentWeek().subscribe(async (deliveries) => {
      if (deliveries) {
        const serverLocallyMergedDeliveries = deliveries.map((serverDelivery) => {
          const isDeliveredLocally = processedDeliveriesSet.has(serverDelivery.id);

          return {
            ...serverDelivery,
            isDelivered: serverDelivery.isDelivered || isDeliveredLocally,
          };
        });

        // Note: NEEDS CLEAR / UPDATE STRATEGY. BE CAREFUL - do not erase local only changes (ie photos or similar).
        // THIS CLEARS ALL LOCAL DELIVERIES. But not the processedDeliveries (entries of driver).
        await this.appIndexedDb.clearDeliveries();
        this.appIndexedDb.bulkAddDeliveries(serverLocallyMergedDeliveries);
      }
    });
  }

  private formatAddress(address: IAddress): string {
    if (address) {
      return `${address.street} ${address.houseNumber}, ${address.postalCode} ${address.city}`;
    } else {
      return '- Ohne Adresse -';
    }
  }

  finishDeliveryLocally(processedDelivery: ProcessedDelivery): void {
    console.log('finishDeliveryLocally - processedDelivery', processedDelivery);
    // store driver's delivery data to dexie
    this.appIndexedDb.addLocallyFinishedDelivery(processedDelivery);

    // TODO "transactionize" local and remote save ? error catching ?
    // TODO: Check if status should be set to IN_PROGRESS first
    this.finishDriverDeliveryMutationCall(processedDelivery).subscribe((res) => {
      console.log('finish mut call res - res', res);
    });
  }

  finishDriverDeliveryMutationCall(processedDelivery: ProcessedDelivery): Observable<any> {
    console.log('finishDriverDeliveryMutationCall - processedDelivery', processedDelivery);
    const {
      deliveryId,
      deliveryProducts,
      messageFromMarket,
      signatureName,
      signatureImage,
      deliveredAtMarketDate,
    } = processedDelivery;

    // Map each product object to the expected GraphQL input format
    const transformedDeliveryProducts = deliveryProducts.map((dP: any) => ({
      id: dP.id,
      deliveryId: dP.deliveryId,
      productId: dP.product.id,
      actualQuantity: dP.actualQuantity,
      returnQuantity: dP.returnQuantity,
    }));

    console.log('finishDriverDeliveryMutationCall - about to call service');
    return this.finishDriverDeliveryService
      .mutate({
        input: {
          deliveryId,
          deliveryProducts: transformedDeliveryProducts,
          messageFromMarket,
          signatureName,
          signatureImage,
          deliveredAtMarketDate,
        },
      })
      .pipe(
        tap((result) => {
          console.log('TAP MUT - result', result);
          if (result.data?.finishDriverDelivery) {
            this.appIndexedDb.updateDeliverySyncStatus(
              deliveryId,
              LocalDeliveryDataSyncStatus.Completed,
            );
          }
        }),
        catchError((error) => {
          // Handle any errors from the GraphQL operation
          console.error('Error finishing delivery:', error);
          return of(false);
        }),
      );
  }

  submitDeliveryPhotoMutationCall(deliveryPhoto: DeliveryPhoto): Observable<any> {
    // console.log('submitDeliveryPhotoMutationCall - photoData', deliveryPhoto);
    const { deliveryId, base64Data, timestamp } = deliveryPhoto;

    // console.log('submitDeliveryPhotoMutationCall - about to call service');
    return this.submitDeliveryPhotoService
      .mutate({
        input: {
          deliveryId,
          title: null,
          imageData: base64Data,
          timestamp
        },
      })
      .pipe(
        tap((result) => {
          console.log('TAP MUT - result', result);
          if (result.data?.submitDeliveryPhoto) {
            // TODO: (!!!) check for photoId... there needs be a ref to the locally saved photo...
            // this.appIndexedDb.updatePhotoSyncStatus(
            //   deliveryId,
            //   LocalDeliveryDataSyncStatus.Completed,
            // );
            console.log('Photo submission successful');
          }
        }),
        catchError((error) => {
          // Handle any errors from the GraphQL operation
          console.error('Error submitting delivery photo:', error);
          return of(false);
        }),
      );
  }

  // SYNC METHODS
  checkForPendingDeliveriesToBeSynced(): void {
    console.log('---> checkForPendingDeliveriesToBeSynced - ');
    this.appIndexedDb
      .getPendingProcessedDeliveries()
      .then((pendingProcessedDeliveries) => {
        pendingProcessedDeliveries.forEach((pendingDelivery) => {
          this.syncProcessedDelivery(pendingDelivery);
        });
      })
      .catch((error) => console.error('Error fetching pending deliveries:', error));
  }

  private syncProcessedDelivery(delivery: ProcessedDelivery): void {
    console.log(`Syncing delivery ${delivery.deliveryId}...`);
    // First, update the delivery status to InProgress
    this.updateDeliverySyncStatus(delivery.deliveryId, LocalDeliveryDataSyncStatus.InProgress)
      .then(() => {
        // Upon successful status update, proceed with the delivery mutation call
        this.finishDriverDeliveryMutationCall(delivery).subscribe({
          next: (result) => {
            console.log(`Delivery ${delivery.deliveryId} finish mutation call successful`, result);

            // TODO: CHECK; ggf überflüssig
            if (result.data?.finishDriverDelivery) {
              console.log('next - RESULT HAS OK!');
              this.updateDeliverySyncStatus(
                delivery.deliveryId,
                LocalDeliveryDataSyncStatus.Completed,
              )
                .then(() => console.log(`Delivery ${delivery.deliveryId} synced successfully.`))
                .catch((error) =>
                  console.error(
                    `Error updating delivery status to Completed for ${delivery.deliveryId}:`,
                    error,
                  ),
                );
            } else {
              console.log('RESULT HAS no OK');
            }

            // If the mutation call was successful, update the status to Completed
          },
          error: (error) => {
            console.error(`Error during sync for delivery ${delivery.deliveryId}:`, error);
            // If an error occurs, update the status to Failed
            this.updateDeliverySyncStatus(
              delivery.deliveryId,
              LocalDeliveryDataSyncStatus.Failed,
            ).catch((error) =>
              console.error(
                `Error updating delivery status to Failed for ${delivery.deliveryId}:`,
                error,
              ),
            );
          },
        });
      })
      .catch((error) =>
        console.error(
          `Error updating delivery status to InProgress for ${delivery.deliveryId}:`,
          error,
        ),
      );
  }

  private updateDeliverySyncStatus(
    deliveryId: number,
    status: LocalDeliveryDataSyncStatus,
  ): Promise<void> {
    console.log(`Updating delivery ${deliveryId} status to ${status}...`);
    // Assuming this method is adjusted to return a Promise
    return this.appIndexedDb.updateDeliverySyncStatus(deliveryId, status);
  }

  // sync photos
  // TODO: photos and deliveries can get stuck in IN_PROGRESS, if there is an auth error because of server restart!
  // TODO: ---> detect and retry/cleanup those.
  async checkForPendingPhotosToBeSynced(): Promise<void> {
    console.log('---> checkForPendingPhotosToBeSynced - ');
    try {
      const pendingDeliveryPhotos = await this.appIndexedDb.get3PendingDeliveryPhotos();
      const failedDeliveryPhotos = await this.appIndexedDb.getFailedDeliveryPhotos();
      const oldInProgressDeliveryPhoto = await this.appIndexedDb.getSingleOldInProgressDeliveryPhotos();

      // Select one random failed delivery photo, if any
      const randomFailedPhoto = failedDeliveryPhotos.length > 0 ? failedDeliveryPhotos[Math.floor(Math.random() * failedDeliveryPhotos.length)] : null;

      if (randomFailedPhoto) {
        const id = randomFailedPhoto.id!;
        const retries = this.photoUploadRetries.get(id) || 0;
        this.photoUploadRetries.set(id, retries + 1);
      }

      if (oldInProgressDeliveryPhoto?.length > 0) {
        const id = oldInProgressDeliveryPhoto[0].id!;
        const retries = this.photoUploadRetries.get(id) || 0;
        this.photoUploadRetries.set(id, retries + 1);
      }

      // Combine pendingDeliveryPhotos with the randomly selected failedDeliveryPhoto if it exists, and oldInProgressPhoto
      const photosToSync = [...pendingDeliveryPhotos];
      if (randomFailedPhoto) photosToSync.push(randomFailedPhoto);
      if (oldInProgressDeliveryPhoto && oldInProgressDeliveryPhoto.length > 0) photosToSync.push(...oldInProgressDeliveryPhoto);

      for (const photo of photosToSync) {
        const retries = this.photoUploadRetries.get(photo.id!) || 0;
        if (retries < 2) {
          await this.syncDeliveryPhoto(photo);
        } else {
          console.log("retried already more than 2 times, continue!");
        }
      }
    } catch (error) {
      console.error('Error fetching or syncing pending photos:', error);
    }
  }

  async syncPhotoById(photoId: number): Promise<void> {
    console.log(`---> syncPhotoById - Photo ID: ${photoId}`);
    try {
      // Retrieve the photo object by ID from the Dexie table.
      const photo = await this.appIndexedDb.getPhotoById(photoId);
      if (!photo) {
        console.log(`Photo with ID ${photoId} not found.`);
        return;
      }

      await this.syncDeliveryPhoto(photo);
      console.log(`Photo with ID ${photoId} has been synced.`);
    } catch (error) {
      console.error(`Error syncing photo with ID ${photoId}:`, error);
    }
  }

  private async syncDeliveryPhoto(pendingPhoto: any): Promise<void> {
    console.log(`Syncing photo for delivery ${pendingPhoto.deliveryId}...`);
    try {
      await this.updatePhotoSyncStatus(pendingPhoto.id, LocalDeliveryDataSyncStatus.InProgress);
      const subscription = this.submitDeliveryPhotoMutationCall(pendingPhoto).subscribe({
        next: async (result) => {
          console.log(
            `Photo submission for delivery ${pendingPhoto.deliveryId} successful`,
            result,
          );
          if (result.data?.submitDeliveryPhoto) {
            console.log('next - PHOTO SUBMISSION OK!');
            await this.updatePhotoSyncStatus(
              pendingPhoto.id,
              LocalDeliveryDataSyncStatus.Completed,
            );
            console.log(
              `Photo ${pendingPhoto.id} for delivery ${pendingPhoto.deliveryId} synced successfully.`,
            );
          } else {
            console.log('PHOTO SUBMISSION RESULT HAS NO OK');
            await this.updatePhotoSyncStatus(pendingPhoto.id, LocalDeliveryDataSyncStatus.Failed);
          }
        },
        error: async (error) => {
          console.error(`Error during sync for photo ${pendingPhoto.id}:`, error);
          await this.updatePhotoSyncStatus(pendingPhoto.id, LocalDeliveryDataSyncStatus.Failed);
        },
        complete: () => {
          subscription.unsubscribe();
        },
      });
    } catch (error) {
      console.error(`Error during photo sync operation for ${pendingPhoto.id}:`, error);
    }
  }

  private updatePhotoSyncStatus(
    photoId: number,
    status: LocalDeliveryDataSyncStatus,
  ): Promise<void> {
    console.log(`Updating photo ${photoId} sync status to ${status}...`);
    // Assuming this method updates the photo's sync status in IndexedDB or any local storage being used
    return this.appIndexedDb.updatePhotoSyncStatus(photoId, status);
  }

  // < SYNC
}
