import { Component, inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { ContentToolbarHeaderComponent } from '../../shared/components/content-toolbar-header/content-toolbar-header.component';
import { ActivatedRoute, Router } from '@angular/router';
import { MOBILE_ROUTE_PATHS } from '../../app.routes';
import { DeliveryDetailsPageService } from './delivery-details-page.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'prf-delivery-details-page',
  templateUrl: './delivery-details.page.component.html',
  styleUrls: ['./delivery-details.page.component.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, ContentToolbarHeaderComponent],
})
export class DeliveryDetailsPageComponent implements OnInit {
  protected readonly MOBILE_ROUTE_PATHS = MOBILE_ROUTE_PATHS;

  private activatedRoute = inject(ActivatedRoute);
  private router = inject(Router);

  private currentDeliveryId: number | null = null;

  viewTitle: string | null = null;
  viewSubTitle: string | null = null;
  isDelivered: boolean = false;

  protected deliveryDetailsPageService = inject(DeliveryDetailsPageService);

  constructor() {
    this.deliveryDetailsPageService.delivery$.pipe(takeUntilDestroyed()).subscribe((delivery) => {
      this.currentDeliveryId = delivery.id;
      this.viewTitle = `LS-Nr. ${delivery.deliverySlipNumber}`;
      this.viewSubTitle = `${delivery.marketName} ${delivery.marketAddress}`;

      this.isDelivered = delivery.isDelivered;
    });
  }

  ngOnInit(): void {
    const paramDeliveryId = this.activatedRoute.snapshot.paramMap.get('deliveryId');
    let detailsDeliveryId: number;
    if (paramDeliveryId) {
      detailsDeliveryId = +paramDeliveryId;
      this.deliveryDetailsPageService.setDeliveryId(detailsDeliveryId);
    } else {
      throw new Error('Unknown deliveryId route param: ' + paramDeliveryId);
      // TODO: Show error toast.
    }
  }

  // TODO: type the tab/subRoute
  navigateTo(subRoute: string): void {
    this.router.navigate([
      `/${MOBILE_ROUTE_PATHS.DELIVERY_DETAILS}/${this.currentDeliveryId}/${subRoute}`,
    ]);
  }

  onNavTabChange($event: any) {
    // TODO: check if nav target isn't already active
    const targetTab = $event.detail.value;

    if (targetTab !== 'delivery-slip') {
      this.deliveryDetailsPageService.closeNumberPadModal();
    }

    if (targetTab) {
      // Needed for close number pad to finish closing. otherwise, number pad stays opened upon tab change.
      setTimeout(() => {
        this.navigateTo(targetTab);
      }, 0);
    }
  }
}
