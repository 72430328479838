import { Injectable } from '@angular/core';

// TODO/NOTES:
// <hline> instead of seperator ---- lines
// https://download4.epson.biz/sec_pubs/pos/reference_en/epos_print/ref_epos_print_xml_en_xmlforcontrollingprinter_hline.html

export interface PrintCompanyInfo {
  name: string;
  address: string;
  phone: string;
  email: string;
}

export interface PrintDeliveryProduct {
  productNo: string;
  description: string;
  actualQuantity: number;
  returnQuantity: number;
  eanCode: string;
}

export interface PrintReceiptData {
  companyInfo: PrintCompanyInfo;
  isDraft: boolean;
  deliverySlipNo: string;
  deliveryDate: string;
  marketDeliverySlipEmail: string;
  products: PrintDeliveryProduct[];
  signatureBase64: string;
  signaturePerson: string;
  currentDate: string;
  currentTime: string;
  totalActualQuantity: number;
  totalReturnQuantity: number;
}

@Injectable({
  providedIn: 'root'
})
export class EpsonPrintService {
  private PRINT_CONFIG = {
    columns: 48,
    font: 'font_e',
    fontSmoothing: 'true',
    lang: 'de'
  } as const;

  private WORD_REPLACEMENTS = {
    'Gefüllt': 'Gef.',
    'Gefüllte': 'Gef.',
    'Getrocknet': 'Getr.',
    'Getrocknete': 'Getr.',
    'Knoblauch': 'Knobl.',
    'ohne': 'o.'
  } as const;

  constructor() {
  }

  public printWithTMAssistant(receiptData: PrintReceiptData, barcodeAlignment: 'left' | 'center' | 'right' = 'left'): void {
    const appScheme = 'tmprintassistant://';
    const host = 'tmprintassistant.epson.com/';
    const action = 'print?';
    const success = encodeURIComponent(window.location.href);
    const ver = '1';
    const timeout = '45000';
    const dataType = 'eposprintxml';
    const reselect = 'yes';

    const xmlData = this.generateXmlData(receiptData, barcodeAlignment);

    const urlData = `${appScheme}${host}${action}` +
      `success=${success}&` +
      `ver=${ver}&` +
      `timeout=${timeout}&` +
      `data-type=${dataType}&` +
      `reselect=${reselect}&` +
      `data=${encodeURIComponent(xmlData)}`;

    console.log(xmlData);

    window.location.href = urlData;
  }

  private generateXmlData(receiptData: PrintReceiptData, barcodeAlignment: 'left' | 'center' | 'right'): string {
    let xml = `
      <epos-print xmlns="http://www.epson-pos.com/schemas/2011/03/epos-print">
        <layout type="receipt" width="800" height="0" margin-top="20" margin-bottom="0" offset-cut="0" offset-label="0"/>
        <text lang="${this.PRINT_CONFIG.lang}"/>
        <text font="${this.PRINT_CONFIG.font}"/>
        <text smooth="${this.PRINT_CONFIG.fontSmoothing}"/>
        <text width="1" height="1"/>

        <text align="center"/>
        <text>&lt;PALDO-LOGO&gt;</text>
<!--        TODO: logo größe maximal wie ENTWURFsbox-->
<!--        <image width="100" height="100">PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgdmlld0JveD0iMCAwIDIwIDIwIj4KICA8ZGVmcz4KICAgIDxzdHlsZT4KICAgICAgLmNscy0xIHsKICAgICAgICBmb250LWZhbWlseTogTXlyaWFkUHJvLVJlZ3VsYXIsICdNeXJpYWQgUHJvJzsKICAgICAgICBmb250LXNpemU6IDI2cHg7CiAgICAgIH0KICAgIDwvc3R5bGU+CiAgPC9kZWZzPgogIDwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAyOC43LjEsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiAxLjIuMCBCdWlsZCAxNDIpICAtLT4KICA8Zz4KICAgIDxnIGlkPSJFYmVuZV8xIj4KICAgICAgPHRleHQgY2xhc3M9ImNscy0xIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxMy4yIDIwLjQpIHJvdGF0ZSgtNDUpIj48dHNwYW4geD0iMCIgeT0iMCI+UDwvdHNwYW4+PC90ZXh0PgogICAgPC9nPgogIDwvZz4KPC9zdmc+</image>-->

        <feed line="1"/>

        <text align="center"/>
        <text>${receiptData.companyInfo.name}</text>
        <feed line="1"/>

        <text>${receiptData.companyInfo.address}</text>
        <feed line="1"/>

        <text>T: ${receiptData.companyInfo.phone}   E: ${receiptData.companyInfo.email}</text>
        <feed line="2"/>

        <text width="2" height="2"/>
        <text>Lieferschein</text>
        <feed line="1"/>

        <text width="2" height="2"/>
        <text em="true">${receiptData.deliverySlipNo}</text>
        <feed line="1"/>
        <text ul="false"></text>
        <text width="1" height="1"/>
        <text>vom ${receiptData.deliveryDate}</text>
        <text em="false"></text>
        <feed line="1"/>

        ${this.generateDraftTopSection(receiptData.isDraft)}
        <feed line="1"/>

        ${this.generateProductsSection(receiptData.products, barcodeAlignment)}

        <text align="right"/>
        <text>Gesamt</text>
        <feed line="1"/>
        <text em="true">${receiptData.totalActualQuantity}/${receiptData.totalReturnQuantity}</text>
        <text em="false"></text>
        <feed line="1"/>

<!-- TODO: Die Kühltemp... hochziehen auf Höhe von "Gesamt" (rechts) -->
        <text align="left"/>
        <text>Die Kühltemperatur von 3°C - 7°C&#10;wurde eingehalten.</text>
        <feed line="1"/>
        <text>Die Lieferung wurde geprüft, entgegengenommen&#10;und unterschrieben von </text>
        <text ul="true">${receiptData.signaturePerson}</text>
        <feed line="2"/>

        <text ul="false"></text>
        <text>Unterschrift: </text>
        <text ul="true">${receiptData.signatureBase64}</text>
        <feed line="2"/>
        <text ul="false"/>

        ${this.generateDraftBottomLegalHintSection(receiptData.isDraft)}

        <feed line="1"/>
        <text>Dieser Lieferschein wird als PDF an die E-Mail-&#10;Adresse ${receiptData.marketDeliverySlipEmail} gesendet.</text>
        <feed line="2"/>

        ${this.generateTimestamp(receiptData.currentDate, receiptData.currentTime)}
        <feed line="2"/>

        <cut type="feed"/>
      </epos-print>
    `;

    // Remove all empty lines.
    xml = xml.replace(/^\s*[\r\n]/gm, '');

    // Remove all leading whitespace.
    xml = xml.replace(/^\s+/gm, '');

    return xml;
  }

  private formatColumn(text: string, width: number, align: 'left' | 'right' = 'left'): string {
    const ellipsis = '...';
    if (text.length > width) {
      if (align === 'left') {
        return text.substring(0, width - ellipsis.length) + ellipsis;
      } else {
        return ellipsis + text.substring(text.length - width + ellipsis.length);
      }
    } else {
      return align === 'left' ? text.padEnd(width) : text.padStart(width);
    }
  }

  // TODO: Add tests for this method, checking the auto lower/uppercasify for the replaced word.
  private replaceKeywords(text: string): string {
    const words = text.split(/\s+/);
    const replacedWords = words.map(word => {
      const lowerWord = word.toLowerCase();
      for (const [key, value] of Object.entries(this.WORD_REPLACEMENTS)) {
        if (lowerWord === key.toLowerCase()) {
          const replaced = word[0] === word[0].toUpperCase()
            ? value[0].toUpperCase() + value.slice(1)
            : value.toLowerCase();
          return replaced;
        }
      }
      return word;
    });

    return replacedWords.join(' ');
  }

  private generateSeparator(): string {
    return `<text>${'-'.repeat(this.PRINT_CONFIG.columns)}</text>`;
  }

  private generateDraftTopSection(isDraft: boolean): string {
    const EMPTY_SPACE = ' ';

    if (!isDraft) return '';
    return `
      <text width="2" height="2"/>
      <text reverse="true" ul="false" em="false"/>
      <text align="center">${EMPTY_SPACE}ENTWURF${EMPTY_SPACE}</text>
      <text width="1" height="1"/>
      <text reverse="false" ul="false" em="false"/>
      <feed line="1"/>
    `;
  }

  private generateDraftBottomLegalHintSection(isDraft: boolean): string {
    if (!isDraft) return '';
    return `
      <text>Lieferschein vor Lieferungsabschluss (Entwurf), dieser Beleg ist nicht rechtsverbindlich.</text>
      <feed line="1"/>
    `;
  }

  private generateProductsSection(products: PrintDeliveryProduct[], barcodeAlignment: 'left' | 'center' | 'right'): string {
    const PRODUCT_NO_WIDTH = 9;
    const DESCRIPTION_WIDTH = 29;
    const QUANTITY_WIDTH = 10;

    if (this.PRINT_CONFIG.columns !== (PRODUCT_NO_WIDTH + DESCRIPTION_WIDTH + QUANTITY_WIDTH)) {
      window.alert('ERROR - EPSON Print: Column widths do not match max width.');
      throw new Error('EPSON print: Column widths do not match max width.');
    }

    // Offset used in order to allow to description rows "blend" into the quantity col. Ie description rows should be longer than its header, to allow for more room for description.
    const DESCR_QUANT_HEADER_COL_OFFSET = 8;
    const header = `<text>${
      this.formatColumn('Art-Nr.', PRODUCT_NO_WIDTH) +
      this.formatColumn('Bezeichnung', DESCRIPTION_WIDTH - DESCR_QUANT_HEADER_COL_OFFSET) +
      this.formatColumn('Lieferm./Rückn.', QUANTITY_WIDTH + DESCR_QUANT_HEADER_COL_OFFSET, 'right')
    }</text>`;

    const DESCR_QUANT_ROW_COL_OFFSET = 3;
    const productLines = products.map(product => {
      const replacedDescription = this.replaceKeywords(product.description);

      return `
        <text>${
        this.formatColumn(product.productNo, PRODUCT_NO_WIDTH) +
        this.formatColumn(replacedDescription, DESCRIPTION_WIDTH + DESCR_QUANT_ROW_COL_OFFSET) +
        this.formatColumn(`${product.actualQuantity}/${product.returnQuantity}`, QUANTITY_WIDTH - DESCR_QUANT_ROW_COL_OFFSET, 'right')
      }</text>
        <feed line="1"/>
        <feed unit="2"/>
        <barcode type="ean13" hri="none" font="font_a" width="4" height="32" align="${barcodeAlignment}">${product.eanCode}</barcode>
        ${this.generateSeparator()}
        <feed line="1"/>
      `;
    }).join('\n');

    return `
      <text align="left"/>
      ${header}
      <feed line="1"/>
      ${this.generateSeparator()}
      <feed line="1"/>
      ${productLines}
    `;
  }

  private generateTimestamp(date: string, time: string): string {
    return `
      <text>${
      this.formatColumn(date, 28) +
      this.formatColumn(`Uhrzeit: ${time}`, 20, 'right')
    }</text>
      `;
  }

}
