import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';

@Component({
  selector: 'prf-messages-tab',
  templateUrl: './delivery-messages-tab.component.html',
  styleUrls: ['./delivery-messages-tab.component.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule],
})
export class DeliveryMessagesTabComponent {
  constructor() {}
}
