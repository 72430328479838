import { inject, Injectable } from '@angular/core';
import { AppIndexedDb } from '../db/app-indexed-db.service';
import { GetAvailableVehiclesService } from '../../../graphql/vehicles-operations.generated';
import { IVehicle } from '@prf/shared/domain';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class VehicleDataService {
  private appIndexedDb = inject(AppIndexedDb);
  private getAvailableVehiclesService = inject(GetAvailableVehiclesService);

  public vehicles$: Observable<IVehicle[]> = this.appIndexedDb.vehicles$;

  constructor() {
    this.pullServerVehicles(); // Note: this is async
  }

  private fetchServerVehicles(): Observable<IVehicle[]> {
    return this.getAvailableVehiclesService.fetch().pipe(
      map((queryResult) => {
        const vehicleEntities = queryResult?.data?.availableDriverVehicles;
        console.log('vehicleEn - vehicleEntities', vehicleEntities);
        return vehicleEntities?.map((vehicleEntity: any): IVehicle => {
          return {
            id: vehicleEntity.id,
            licensePlate: vehicleEntity.licensePlate,
            vehicleModel: vehicleEntity.vehicleModel,
          };
        });
      }),
    );
  }

  async pullServerVehicles() {
    // TODO: check for unubscribe / take(1)
    this.fetchServerVehicles().subscribe(async (vehicles) => {
      if (vehicles) {
        // Note: only clear, if there are fetched vehicles! Otherwise, use locally stored vehicles
        await this.appIndexedDb.clearVehicles();
        this.appIndexedDb.bulkAddVehicles(vehicles);
      }
    });
  }
}
