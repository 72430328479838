import { IEntity } from "./entity.interface";

export enum EIngredientUnit {
  GRAM = "GRAM",
  LITER = "LITER",
}

// Component of a recipe.
export interface IIngredient extends IEntity {
  name: string;
  unit: EIngredientUnit;
}

export interface IRecipeIngredient extends IEntity {
  ingredient: IIngredient;
  quantity: number;
}
