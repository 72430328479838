import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, filter, map, Observable, switchMap } from 'rxjs';
import dayjs from 'dayjs';
import { DeliveriesDataService } from '../../shared/services/data/deliveries-data.service';
import { DeliveryTour } from '@prf/shared/domain';
import { isToday } from "../deliveries-week/deliveries-week.page.component";

@Injectable({
  providedIn: 'root',
})
export class DeliveryMarketsTourPageService {
  private currentDate$ = new BehaviorSubject<string>('');
  deliveryTour$: Observable<DeliveryTour>;

  private deliveriesDataService = inject(DeliveriesDataService);
  constructor() {
    this.deliveryTour$ = this.initDeliveryTourObservable();
  }

  setDate(date: string): void {
    this.currentDate$.next(date);
  }

  private initDeliveryTourObservable(): Observable<DeliveryTour> {
    return this.currentDate$.pipe(
      filter((date) => !!date), // Skip empty dates.
      switchMap((date: string) => {
        return this.deliveriesDataService.deliveries$.pipe(
          map((deliveries) => {
            const deliveriesForCurrentDate = deliveries.filter(
              (delivery) => this.formatDate(delivery.deliveryDate) === date,
            );

            // Sort by PLZ
            deliveriesForCurrentDate.sort((a, b) => a.marketPostalCode.localeCompare(b.marketPostalCode));

            const uniqueMarkets = new Set(
              deliveriesForCurrentDate.map((d) => d.marketId),
            );

            return {
              date,
              numMarkets: uniqueMarkets.size,
              isToday: isToday(date),
              deliveries: deliveriesForCurrentDate,
            };
          }),
        );
      }),
    );
  }

  formatDate(date: string | Date) {
    if (!date) {
      return '';
    }
    // info: postgres uses format "yyyy-mm-dd" for dates
    return dayjs(date).format('YYYY-MM-DD');
  }

}
