<ion-modal #amountNumberPadModal
           [isOpen]="isOpen"
           [showBackdrop]="false"
           [backdropDismiss]="false"
           [initialBreakpoint]="0.5"
           [backdropBreakpoint]="0.5"
           [breakpoints]="[0, 0.5]"
           (didDismiss)="didDismiss.emit($event)"
>
  <ng-template>
    <ion-content>
      <div style="background: #f1f1f1; height: 100%;">
        <prf-amount-number-pad [item]="item"
                               [quantityType]="quantityType"
                               (confirm)="onConfirm()"
                               (next)="onNext()"
                               (numberChange)="onAmountNumberPadNumberChange($event)"
                               (minimize)="onMinimizeAmountNumberPad()"
        />
      </div>
    </ion-content>
  </ng-template>
</ion-modal>

