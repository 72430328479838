
export enum DocumentContentType {
  INVOICE = 'INVOICE',
  DELIVERY_SLIP = 'DELIVERY_SLIP',
}

export enum DocumentEntityType {
  INVOICE = 'INVOICE',
  DELIVERY = 'DELIVERY',
}
