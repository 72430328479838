<ion-content>
  <div class="ion-padding">
    <h1>Fotos der Lieferung</h1>
  </div>
  <ng-container *ngIf="photos$ | async as photos">
    <ng-container *ngIf="photos?.length === 0">
      <div class="ion-padding">
        <p>Keine Fotos vorhanden.</p>
      </div>
    </ng-container>
    <ion-grid>
      <ion-row>
        <ion-col size="6" *ngFor="let photo of photos;">
          <div style="position: relative;">
            <ion-img [src]="photo.base64Data" [class]="photo.syncStatus"></ion-img>
            <ion-badge *ngIf="photo.syncStatus === 'COMPLETED'" color="success">
              <ion-icon class="status" name="checkmark"></ion-icon>
              <span class="label">Hochgeladen</span>
            </ion-badge>
            <ion-badge *ngIf="photo.syncStatus !== 'COMPLETED'" color="secondary">
              <ion-icon class="status" style="margin-left: 1px;" name="sync-outline"></ion-icon>
            </ion-badge>
          </div>
        </ion-col>
      </ion-row>
    </ion-grid>

    <ion-fab vertical="bottom" horizontal="center" slot="fixed">
      <ion-fab-button>
        <ion-icon name="camera" (click)="onClickTakePhoto()"></ion-icon>
      </ion-fab-button>
    </ion-fab>
  </ng-container>
</ion-content>
