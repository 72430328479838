import { Component, inject, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { PhotoService } from './services/photo.service';
import { FormsModule } from '@angular/forms';
import {
  AppIndexedDb,
  DeliveryPhoto,
  LocalDeliveryDataSyncStatus
} from '../../../../shared/services/db/app-indexed-db.service';
import { DeliveryDetailsPageService } from '../../delivery-details-page.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { distinctUntilChanged, fromEvent, map, Observable, startWith, Subscription } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';
import { MOBILE_ROUTE_PATHS } from '../../../../app.routes';

enum DeviceOrientation {
  PORTRAIT,
  LANDSCAPE,
}

@Component({
  selector: 'prf-delivery-photos-page',
  templateUrl: './delivery-photos-tab.component.html',
  styleUrls: ['./delivery-photos-tab.component.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, FormsModule],
})
export class DeliveryPhotosTabComponent implements OnDestroy {
  public photos$!: Observable<DeliveryPhoto[]>;

  private photoService = inject(PhotoService);
  private appIndexedDb = inject(AppIndexedDb);
  protected deliveryDetailsPageService = inject(DeliveryDetailsPageService);
  private router = inject(Router);
  private currentDeliveryId!: number;

  // TODO: Refactor/Performance: Might be improved by using a MutationObserver, instead of calling checks via interval.
  private shutterButtonCheckInterval?: number;
  private shutterButton: HTMLDivElement | null = null;
  private orientationSubscription?: Subscription;
  private cameraIsInPortraitMode = true;

  constructor() {
    this.deliveryDetailsPageService.delivery$.pipe(takeUntilDestroyed()).subscribe((delivery) => {
      this.currentDeliveryId = delivery.id;
      this.photos$ = this.appIndexedDb.getPhotosForDelivery(this.currentDeliveryId);
    });

    this.router.events.pipe(takeUntilDestroyed()).subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // console.log('nav end - router event - event', event);
        if (event.url.endsWith(`/${MOBILE_ROUTE_PATHS.PHOTOS}`)) {
          // console.log('--> route to photos, init listeners/subscr');
          this.setupShutterButtonListener();
          this.subscribeToOrientationChanges();
        } else {
          // console.log('-z-> route change CLEAR subscr route change - ');
          this.clearShutterButtonCheck();
          this.orientationSubscription?.unsubscribe();
        }
      }
    });

    this.handleShutterButtonClick = this.handleShutterButtonClick.bind(this);
  }

  ngOnDestroy(): void {
    // console.warn('ngOnDestroy - PHOTO TAB');
    this.clearShutterButtonCheck();
    this.orientationSubscription?.unsubscribe();
  }

  async onClickTakePhoto() {
    const capturedPhoto = await this.photoService.capturePhoto();
    // console.log('onClickTakePhoto - capturedPhoto =', capturedPhoto);
    if (capturedPhoto) {
      const photoData = await this.photoService.getPhotoData(
        capturedPhoto,
        this.cameraIsInPortraitMode,
      );

      const photo = {
        deliveryId: this.currentDeliveryId,
        base64Data: photoData.base64Data,
        timestamp: new Date(),
        syncStatus: LocalDeliveryDataSyncStatus.Pending,
      };
      const locallyAddedPhotoId = await this.appIndexedDb.addCapturedPhoto(photo);
      const isPhotoIdValid = locallyAddedPhotoId !== undefined && locallyAddedPhotoId !== null;
      const shouldUploadImmediately = true;

      if (shouldUploadImmediately && isPhotoIdValid && navigator.onLine) {
        this.deliveryDetailsPageService.syncDeliveryPhotoById(locallyAddedPhotoId);
      } else {
        console.log("NOT uploading immediately.");
      }
    }
  }

  private handleShutterButtonClick(): void {
    console.log(
      `Shutter button clicked, this.cameraIsInPortraitMode is currently set to: ${this.cameraIsInPortraitMode}.`,
    );
  }

  private setupShutterButtonListener(): void {
    // console.warn('setupShutterButtonListener - ');
    this.shutterButtonCheckInterval = window.setInterval(() => {
      const cameraModalElement = document.querySelector('pwa-camera-modal-instance');
      const cameraModalShadowRoot = cameraModalElement?.shadowRoot;
      if (cameraModalShadowRoot) {
        const cameraElement = cameraModalShadowRoot.querySelector('pwa-camera');
        const cameraShadowRoot = cameraElement?.shadowRoot;
        if (cameraShadowRoot) {
          const shutterButton = cameraShadowRoot.querySelector(
            '.shutter-button',
          ) as HTMLDivElement | null;
          if (shutterButton && shutterButton !== this.shutterButton) {
            // console.log('REMOVING and reading event listener - ');
            this.shutterButton?.removeEventListener('click', this.handleShutterButtonClick);
            this.shutterButton = shutterButton;
            // console.log('during event list add/remove - this.shutterButton', this.shutterButton);
            this.shutterButton.addEventListener('click', this.handleShutterButtonClick);
          }
        }
      }
    }, 500);
  }

  private getOrientation(): Observable<DeviceOrientation> {
    return fromEvent<DeviceOrientationEvent>(window, 'deviceorientation').pipe(
      map((event) => {
        const isPortrait = Math.abs(event.beta!) > Math.abs(event.gamma!);
        return isPortrait ? DeviceOrientation.PORTRAIT : DeviceOrientation.LANDSCAPE;
      }),
      startWith(DeviceOrientation.PORTRAIT),
      distinctUntilChanged(), // ensures updates are only sent upon changes
    );
  }

  private subscribeToOrientationChanges(): void {
    this.orientationSubscription = this.getOrientation().subscribe((orientation) => {
      this.cameraIsInPortraitMode = orientation === DeviceOrientation.PORTRAIT;
      // console.log(`Orientation changed: now in ${orientation} ${DeviceOrientation[orientation]} mode.`);
    });
  }

  private clearShutterButtonCheck() {
    // console.warn('clearShutterButtonCheck - ');
    if (this.shutterButtonCheckInterval) {
      clearInterval(this.shutterButtonCheckInterval);
      this.shutterButtonCheckInterval = undefined;
    }
    this.shutterButton?.removeEventListener('click', this.handleShutterButtonClick);
  }
}
