import { CommonModule } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { ContentToolbarHeaderComponent } from '../../shared/components/content-toolbar-header/content-toolbar-header.component';
import { Router } from '@angular/router';
import { MOBILE_ROUTE_PATHS } from '../../app.routes';
import { VehicleSelectionPageService } from './vehicle-selection-page.service';
import { FormsModule } from '@angular/forms';
import { MobileInspectService } from '../../shared/services/inspect/mobile-inspect.service';

@Component({
  selector: 'prf-vehicle-selection-page',
  templateUrl: './vehicle-selection.page.component.html',
  styleUrls: ['./vehicle-selection.page.component.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, ContentToolbarHeaderComponent, FormsModule],
})
export class VehicleSelectionPageComponent implements OnInit {
  private router = inject(Router);
  protected vehicleSelectionPageService = inject(VehicleSelectionPageService);
  private mobileInspectService = inject(MobileInspectService);

  selectedVehicle: any = null;

  constructor() {}

  ngOnInit() {}

  ionViewWillEnter(): void {
    setTimeout(() => {
      this.mobileInspectService.cleanupLocalDb();
    }, 100);

    setTimeout(() => {
      this.mobileInspectService.triggerInspect();

      // TODO: disable after 2024-05-16
      // update local deliveries (fixes)
      this.mobileInspectService.fixLocalDeliveries();
    }, 750);
  }

  onClickConfirmVehicle() {
    // TODO: SET IN DB -> vehicle => selected
    // via perfo call: Fahrzeug "locken", solange User aktiv/eingeloggt ist (Session aktiv). Gelockte Fahrzeuge werden bei anderen Nutzern dann als in Nutzung / ausgegraut angezeigt.
    console.log('onClickConfirmVehicle - this.selectedVehicle', this.selectedVehicle);
    // TODO: Loading spinner. Disable button.
    this.router.navigate([MOBILE_ROUTE_PATHS.DELIVERIES_WEEK]);
  }
}
