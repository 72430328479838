import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { DeliveryDetailsPageService } from '../../delivery-details-page.service';
import { FormsModule } from '@angular/forms';
import { InputComponent } from '../../../../core/components/input/input.component';
import { EpsonPrintService, PrintReceiptData } from '../../../../shared/services/print/epson-print.service';
import { IDriverDelivery } from '@prf/shared/domain';

@Component({
  selector: 'prf-delivery-market-info-tab',
  templateUrl: './delivery-market-info-tab.component.html',
  styleUrls: ['./delivery-market-info-tab.component.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, FormsModule, InputComponent]
})
export class DeliveryMarketInfoTabComponent {
  protected deliveryDetailsPageService = inject(DeliveryDetailsPageService);
  protected printService = inject(EpsonPrintService);

  isDraftPrint: boolean = true;
  barcodeAlignment: 'left' | 'center' | 'right' = 'center';

  constructor() {
  }

  printWithTmAssistant(delivery: IDriverDelivery, isDraft: boolean, barcodeAlignment: 'left' | 'center' | 'right'): void {
    const products = [
      {
        'productNo': '101',
        'description': 'Grüne Oliven ohne Kern',
        'actualQuantity': 12,
        'returnQuantity': 1,
        'eanCode': '4262382240009'
      },
      {
        'productNo': '102',
        'description': 'Grüne Oliven ohne Kern mit Knoblauch',
        'actualQuantity': 321,
        'returnQuantity': 123,
        'eanCode': '4262382240016'
      },
      // {
      //   'productNo': '103',
      //   'description': 'Original Kalamata Oliven',
      //   'actualQuantity': 14,
      //   'returnQuantity': 9,
      //   'eanCode': '4262382240023'
      // },
      // {
      //   'productNo': '104',
      //   'description': 'Geschwärzte Oliven natur',
      //   'actualQuantity': 12,
      //   'returnQuantity': 1,
      //   'eanCode': '4262382240030'
      // },
      // {
      //   'productNo': '105',
      //   'description': 'Geschwärzte Oliven mit Knoblauch',
      //   'actualQuantity': 12,
      //   'returnQuantity': 1,
      //   'eanCode': '4262382240047'
      // },
      // {
      //   'productNo': '108',
      //   'description': 'Peperoni Kräuter-Knoblauch',
      //   'actualQuantity': 5,
      //   'returnQuantity': 0,
      //   'eanCode': '4262382240061'
      // },
      // {
      //   'productNo': '109',
      //   'description': 'Getrocknete Tomaten (eingelegt)',
      //   'actualQuantity': 12,
      //   'returnQuantity': 1,
      //   'eanCode': '4262382240078'
      // },
      // {
      //   'productNo': '110',
      //   'description': 'Gefüllte Peperoni',
      //   'actualQuantity': 12,
      //   'returnQuantity': 1,
      //   'eanCode': '4262382240085'
      // },
      // {
      //   'productNo': '111',
      //   'description': 'Gefüllte Kirschpaprika',
      //   'actualQuantity': 1,
      //   'returnQuantity': 1,
      //   'eanCode': '4262382240092'
      // },
      // {
      //   'productNo': '112',
      //   'description': 'Scharfe Würfel',
      //   'actualQuantity': 12,
      //   'returnQuantity': 1,
      //   'eanCode': '4262382240108'
      // },
      // {
      //   'productNo': '113',
      //   'description': 'Kräuter Würfel',
      //   'actualQuantity': 12,
      //   'returnQuantity': 1,
      //   'eanCode': '4262382240115'
      // },
      // {
      //   'productNo': '115',
      //   'description': 'Gefüllte Weinblätter mit Reis',
      //   'actualQuantity': 12,
      //   'returnQuantity': 1,
      //   'eanCode': '4262416060009'
      // },
      // {
      //   'productNo': '116',
      //   'description': 'Oliven Mix',
      //   'actualQuantity': 12,
      //   'returnQuantity': 1,
      //   'eanCode': '4262416060061'
      // },
      // {
      //   'productNo': '201',
      //   'description': 'Frischer Krautsalat',
      //   'actualQuantity': 12,
      //   'returnQuantity': 1,
      //   'eanCode': '4262382240139'
      // },
      // {
      //   'productNo': '202',
      //   'description': 'Scharfer Krautsalat',
      //   'actualQuantity': 12,
      //   'returnQuantity': 1,
      //   'eanCode': '4262382240146'
      // },
      // {
      //   'productNo': '203',
      //   'description': 'Coleslaw',
      //   'actualQuantity': 12,
      //   'returnQuantity': 1,
      //   'eanCode': '4262382240153'
      // },
      // {
      //   'productNo': '204',
      //   'description': 'Champignons',
      //   'actualQuantity': 12,
      //   'returnQuantity': 1,
      //   'eanCode': '4262382240160'
      // },
      // {
      //   'productNo': '205',
      //   'description': 'Cous Cous Salat',
      //   'actualQuantity': 12,
      //   'returnQuantity': 1,
      //   'eanCode': '4262382240177'
      // },
      // {
      //   'productNo': '206',
      //   'description': 'Bulgursalat',
      //   'actualQuantity': 12,
      //   'returnQuantity': 1,
      //   'eanCode': '4262382240184'
      // },
      // {
      //   'productNo': '209',
      //   'description': 'Rotkohl-Salat',
      //   'actualQuantity': 62,
      //   'returnQuantity': 33,
      //   'eanCode': '4262416060078'
      // },
      // {
      //   'productNo': '301',
      //   'description': 'Frischer Zaziki',
      //   'actualQuantity': 112,
      //   'returnQuantity': 100,
      //   'eanCode': '4262382240191'
      // },
      // {
      //   'productNo': '302',
      //   'description': 'Paprika-Paste',
      //   'actualQuantity': 12,
      //   'returnQuantity': 1,
      //   'eanCode': '4262382240207'
      // },
      // {
      //   'productNo': '303',
      //   'description': 'Granatapfel-Paste',
      //   'actualQuantity': 12,
      //   'returnQuantity': 1,
      //   'eanCode': '4262382240214'
      // },
      // {
      //   'productNo': '304',
      //   'description': 'Scharfe-Paste',
      //   'actualQuantity': 12,
      //   'returnQuantity': 1,
      //   'eanCode': '4262382240221'
      // },
      // {
      //   'productNo': '305',
      //   'description': 'Tomaten-Paste',
      //   'actualQuantity': 12,
      //   'returnQuantity': 1,
      //   'eanCode': '4262382240238'
      // },
      // {
      //   'productNo': '306',
      //   'description': 'Aioli',
      //   'actualQuantity': 12,
      //   'returnQuantity': 1,
      //   'eanCode': '4262382240245'
      // },
      // {
      //   'productNo': '307',
      //   'description': 'Porree-Paste',
      //   'actualQuantity': 12,
      //   'returnQuantity': 1,
      //   'eanCode': '4262382240252'
      // },
      // {
      //   'productNo': '309',
      //   'description': 'Auberginen-Paste',
      //   'actualQuantity': 12,
      //   'returnQuantity': 1,
      //   'eanCode': '4262382240276'
      // },
      // {
      //   'productNo': '310',
      //   'description': 'Kräuter-Knoblauch-Paste',
      //   'actualQuantity': 12,
      //   'returnQuantity': 1,
      //   'eanCode': '4262382240283'
      // },
      // {
      //   'productNo': '311',
      //   'description': 'Dattel Paste',
      //   'actualQuantity': 12,
      //   'returnQuantity': 1,
      //   'eanCode': '4262416060030'
      // },
      // {
      //   'productNo': '312',
      //   'description': 'Trüffel Paste',
      //   'actualQuantity': 12,
      //   'returnQuantity': 1,
      //   'eanCode': '4262416060108'
      // },
      {
        'productNo': '314',
        'description': 'Thunfisch-Paste',
        'actualQuantity': 12,
        'returnQuantity': 1,
        'eanCode': '4262416060115'
      }
    ];

    // TODO: add types for keys... ie pick/keyof from IDeliveryProduct
    const calculateTotal = (key: 'actualQuantity' | 'returnQuantity'): number => {
      return products.reduce((sum, product) => sum + product[key], 0);
    };

    const receiptData: PrintReceiptData = {
      companyInfo: {
        name: 'PALDO GmbH',
        address: 'Heinrich-Brauns-Str. 17, 45355 Essen',
        phone: '0201 17754771',
        email: 'info@paldo.de'
      },
      isDraft: isDraft,
      deliverySlipNo: delivery.deliverySlipNumber!,
      deliveryDate: new Date(delivery.deliveryDate).toLocaleDateString('de-DE', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
      }),
      products: products,
      signatureBase64: 'base64EncodedSignature',
      signaturePerson: 'Herr Mustermann',
      marketDeliverySlipEmail: delivery.marketDeliverySlipEmail,
      currentDate: new Date().toLocaleString('de-DE', {
        weekday: 'short',
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
      }),
      currentTime: new Date().toLocaleString('de-DE', {
        hour: '2-digit',
        minute: '2-digit'
      }),
      totalActualQuantity: calculateTotal('actualQuantity'),
      totalReturnQuantity: calculateTotal('returnQuantity')
    };

    this.printService.printWithTMAssistant(receiptData, barcodeAlignment);
  }

}
