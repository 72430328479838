import { ChangeDetectionStrategy, Component, inject, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from "@ionic/angular";
import { MOBILE_ROUTE_PATHS } from '../../../app.routes';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth/auth.service';

@Component({
  selector: 'prf-content-toolbar-header',
  standalone: true,
  imports: [CommonModule, IonicModule],
  templateUrl: './content-toolbar-header.component.html',
  styleUrls: ['./content-toolbar-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentToolbarHeaderComponent implements OnInit {

  USER_ACTION_LOGOUT_KEY = 'logout';

  @Input()
  title?: string;

  @Input()
  subTitle?: string;

  private router = inject(Router);
  private authService = inject(AuthService);

  public currentUsername!: string;

  public userMenuActionSheetButtons = [
    {
      text: 'Benutzer abmelden',
      role: 'destructive',
      data: {
        action: this.USER_ACTION_LOGOUT_KEY,
      },
    },
    {
      text: 'Schließen',
      role: 'cancel',
      data: {
        action: 'cancel',
      },
    },
  ];

  ngOnInit() {
    this.currentUsername = this.authService.getSessionUsername();
  }

  onClickHome() {
    this.router.navigate([MOBILE_ROUTE_PATHS.DELIVERIES_WEEK]);
  }

  onClickLogout() {
    // TODO: Check whether param skipLogoutRequest is needed
    this.authService.logout();
  }

  get shouldShowLogout(): boolean {
    return this.homePageIsActive();
  }

  private homePageIsActive(): boolean {
    return this.router.url.includes(MOBILE_ROUTE_PATHS.DELIVERIES_WEEK);
  }

  onUserSettingsActionSheetDismiss($event: any) {
    if ($event?.detail?.data?.action === this.USER_ACTION_LOGOUT_KEY) {
      this.onClickLogout();
    }
  }
}
