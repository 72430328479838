import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, filter, map, Observable, shareReplay, Subject, switchMap } from 'rxjs';
import dayjs from 'dayjs';
import { DeliveriesDataService } from '../../shared/services/data/deliveries-data.service';
import { IDriverDelivery } from '@prf/shared/domain';
import { ProcessedDelivery } from '../../shared/services/db/app-indexed-db.service';

@Injectable({
  providedIn: 'root',
})
export class DeliveryDetailsPageService {
  private currentDeliveryId = new BehaviorSubject<number | null>(null);
  public currentDeliveryId$ = this.currentDeliveryId.asObservable();

  delivery$: Observable<IDriverDelivery>;

  private deliveriesDataService = inject(DeliveriesDataService);

  private _closeNumberPadModal = new Subject<void>();
  public closeNumberPadModal$ = this._closeNumberPadModal.asObservable();

  constructor() {
    this.delivery$ = this.initDeliveryObservable();
  }

  setDeliveryId(deliveryId: number): void {
    this.currentDeliveryId.next(deliveryId);
  }

  finishDelivery(processedDelivery: ProcessedDelivery) {
    this.deliveriesDataService.finishDeliveryLocally(processedDelivery);
  }

  async syncDeliveryPhotoById(photoId: number): Promise<void> {
    console.log(`---> syncDeliveryPhotoById - Photo ID: ${photoId}`);
    try {
      await this.deliveriesDataService.syncPhotoById(photoId);
      console.log(`Photo with ID ${photoId} has been requested to sync.`);
    } catch (error) {
      console.error(`Error requesting sync for photo with ID ${photoId}:`, error);
    }
  }

  formatDate(date: string | Date) {
    if (!date) {
      return '';
    }
    // info: postgres uses format "yyyy-mm-dd" for dates
    return dayjs(date).format('YYYY-MM-DD');
  }

  formatHeaderDate(date: string | Date | null): string {
    if (!date) {
      return '';
    }
    return dayjs(date).format('dddd, DD.MM.YYYY');
  }

  closeNumberPadModal(): void {
    this._closeNumberPadModal.next();
  }

  private initDeliveryObservable(): Observable<IDriverDelivery> {
    return this.currentDeliveryId.pipe(
      filter((deliveryId): deliveryId is number => deliveryId !== null),
      switchMap((deliveryId: number) => {
        return this.deliveriesDataService.deliveries$.pipe(
          map((deliveries: IDriverDelivery[]) => {
            const matchingDelivery = deliveries.find((delivery) => delivery.id === deliveryId);

            if (!matchingDelivery) {
              throw new Error(`No delivery found for ID ${deliveryId}`);
            }

            return matchingDelivery;
          }),
        );
      }),
      shareReplay(1),
    );
  }
}
