// Interfaces
export * from './lib/models/entity.interface';

export * from './lib/models/delivery.interface';
export * from './lib/models/ingredient.interface';
export * from './lib/models/invoice.interface';
export * from './lib/models/market.interface';
export * from './lib/models/product.interface';
export * from './lib/models/retailer.interface';
export * from './lib/models/user.interface';
export * from './lib/models/document-ref.interface';

export * from './lib/models/recipe.interface';
export * from './lib/models/vehicle.interface';
export * from './lib/models/address.interface';
export * from './lib/models/key-value.interface';

// API
export * from './lib/api/auth.interface';

// Constants
export * from './lib/constants/app-version.constant';

// Mock data
// export * from './lib/mock-data/deliveries.mock-data';
// export * from './lib/mock-data/ingredients.mock-data';
// export * from './lib/mock-data/invoices.mock-data';
// export * from './lib/mock-data/markets.mock-data';
// export * from './lib/mock-data/products.mock-data';
// export * from './lib/mock-data/retailers.mock-data';
// export * from './lib/mock-data/users.mock-data';
// export * from './lib/mock-data/vehicles.mock-data';
