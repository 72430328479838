import { Component, inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule, IonModal } from '@ionic/angular';
import { SignaturePadComponent } from './components/signature-pad/signature-pad.component';
import { Router } from '@angular/router';
import { MOBILE_ROUTE_PATHS } from '../../../../app.routes';
import { FormsModule } from '@angular/forms';
import { AmountNumberPadComponent } from './components/amount-number-pad/amount-number-pad.component';
import { AmountNumberPadModalComponent } from './components/amount-number-pad-modal/amount-number-pad-modal.component';
import {
  SignaturePadBase64Image,
  SignaturePadModalComponent,
} from './components/signature-pad-modal/signature-pad-modal.component';
import { InputComponent } from '../../../../core/components/input/input.component';
import {
  ProductAmountGridComponent,
  ProductInputChangeEvent,
  ProductInputFocusEvent,
  QuantityType,
} from './components/product-amount-grid/product-amount-grid.component';
import * as dayjs from 'dayjs';
import 'dayjs/locale/de';
import { DeliveryDetailsPageService } from '../../delivery-details-page.service';
import { IDeliveryProduct, IDriverDelivery, IDriverDeliveryProduct } from '@prf/shared/domain';
import { FormatDatePipe } from '../../../../shared/pipes/format-date.pipe';
import {
  ProcessedDelivery,
  LocalDeliveryDataSyncStatus,
} from '../../../../shared/services/db/app-indexed-db.service';
import { interval, Subject, takeUntil } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AlertController } from '@ionic/angular/standalone';

dayjs.locale('de');

@Component({
  selector: 'prf-delivery-slip-tab',
  templateUrl: './delivery-slip-tab.component.html',
  styleUrls: ['./delivery-slip-tab.component.scss'],
  standalone: true,
  imports: [
    IonicModule,
    CommonModule,
    SignaturePadComponent,
    FormsModule,
    AmountNumberPadComponent,
    AmountNumberPadModalComponent,
    SignaturePadModalComponent,
    InputComponent,
    ProductAmountGridComponent,
    FormatDatePipe,
  ],
})
export class DeliverySlipTabComponent implements OnInit, OnDestroy {
  private router = inject(Router);
  private alertController = inject(AlertController);
  protected deliveryDetailsPageService = inject(DeliveryDetailsPageService);
  protected currentDelivery$ = this.deliveryDetailsPageService.delivery$;

  selectedTitle: 'Herr' | 'Frau' | 'keine Angabe' = 'Herr';

  wizardStep = 1;

  @ViewChild('amountNumberPadModal') amountNumberPadModal!: IonModal;
  @ViewChild('signaturePadModal') signaturePadModal!: IonModal;

  @ViewChild(ProductAmountGridComponent) productAmountGridComp!: ProductAmountGridComponent;

  isSignatureModalOpen = false;
  isNumberPadModalOpen = false;

  amountGridFocusedItem: Partial<IDriverDeliveryProduct> | null = null;
  amountGridFocusedQuantityType: QuantityType | null = null;

  currentTimeDate = new Date();
  private stopTimeUpdates = new Subject<void>();

  private signatureBase64: string | null = null;

  constructor() {
    this.deliveryDetailsPageService.closeNumberPadModal$
      .pipe(takeUntilDestroyed())
      .subscribe((_) => {
        this.isNumberPadModalOpen = false;
      });
  }

  signaturePersonName: string = '';

  messageFromMarketToOffice: string = '';

  ngOnInit() {
    this.startUpdatingCurrentTime();
  }

  ngOnDestroy() {
    this.stopTimeUpdates.next();
    this.stopTimeUpdates.complete();
  }

  startUpdatingCurrentTime() {
    interval(15_000) // Emit every 15 seconds
      .pipe(takeUntil(this.stopTimeUpdates))
      .subscribe(() => {
        this.currentTimeDate = new Date();
      });
  }

  getSignatureBase64Data(): string | null {
    return this.signatureBase64;
  }

  getSignaturePersonName(): string {
    if (this.selectedTitle === 'keine Angabe') {
      return `(keine Angabe) ${this.signaturePersonName}`;
    } else {
      return `${this.selectedTitle} ${this.signaturePersonName}`;
    }
  }

  onClickNextStep() {
    this.wizardStep = this.wizardStep + 1;
  }

  onClickPrevStep() {
    this.wizardStep = this.wizardStep - 1;
  }

  // TODO: ionic alert: show summary infos - ie X photos, X delivered, Y retoure
  async presentConfirmationAlert(delivery: IDriverDelivery) {
    const alert = await this.alertController.create({
      header: 'Lieferung abschließen',
      // message: 'Jetzt abschließen?',
      backdropDismiss: false,
      animated: true,
      buttons: [
        {
          text: 'Abbrechen',
          role: 'cancel',
          handler: () => {
            console.log('Cancel action');
          },
        },
        {
          text: 'Bestätigen',
          role: 'confirm',
          handler: () => {
            // TODO: Extract into vibrate service - success vibrate
            navigator.vibrate(150); // Vibrate for 150ms.
            this.completeDelivery(delivery);
          },
        },
      ],
    });

    await alert.present();
  }

  private completeDelivery(delivery: IDriverDelivery) {
    this.stopTimeUpdates.next();

    this.finishDelivery({
      deliveryId: delivery.id,
      messageFromMarket: this.messageFromMarketToOffice,
      signatureImage: this.getSignatureBase64Data()!,
      signatureName: this.getSignaturePersonName(),
      deliveredAtMarketDate: this.currentTimeDate,
      // TODO: check whether this manipulates the original delivery products
      // TODO: remove typename globally
      deliveryProducts: delivery.deliveryProducts.map(
        (dP: Partial<IDeliveryProduct> & { __typename?: string }) => {
          delete dP.__typename;
          return dP;
        },
      ),
      syncStatus: LocalDeliveryDataSyncStatus.Pending,
    });
  }

  onClickFinishDelivery(delivery: IDriverDelivery) {
    this.presentConfirmationAlert(delivery);
  }

  shouldShowPrevButton(): boolean {
    return this.wizardStep > 1;
  }

  shouldShowNextStepButton() {
    return this.wizardStep > 0 && this.wizardStep < 3;
  }

  shouldShowFinishDelivery() {
    return this.wizardStep === 3;
  }

  onDidDismissNumPadModal() {
    this.isNumberPadModalOpen = false;
    this.amountGridFocusedItem = null;
    this.amountGridFocusedQuantityType = null;
  }

  onDidDismissSignaturePadModal($event: { detail: { data: SignaturePadBase64Image } }) {
    this.signatureBase64 = $event.detail.data.signatureBase64;
    this.isSignatureModalOpen = false;
  }

  setFocussedAmountGridItem(event: ProductInputFocusEvent) {
    this.amountGridFocusedItem = event.item;
    this.isNumberPadModalOpen = true;
    this.amountGridFocusedQuantityType = event.quantityType;
  }

  onNumberPadNumberChangeEvent($event: ProductInputChangeEvent) {
    if (this.amountGridFocusedItem) {
      (this.amountGridFocusedItem as Partial<IDriverDeliveryProduct>)[
        this.amountGridFocusedQuantityType as QuantityType
      ] = $event.value;
    }
  }

  onNumberPadConfirm(): void {
    this.productAmountGridComp.handleConfirmItem();
  }

  onNumberPadNext(): void {
    this.productAmountGridComp.handleNextItem();
  }

  hasSignature(): boolean {
    return !!this.signatureBase64;
  }

  isFinishDeliveryDisabled(currentDelivery: IDriverDelivery): boolean {
    if (currentDelivery.isDelivered) {
      return true;
    }

    return !this.signaturePersonName || !this.hasSignature();
  }

  // TODO: test on device: what happens to view/state if deliveryInput view is opened, has entries/edits, and then app gets minimized -> reoponed?
  private finishDelivery(processedDelivery: ProcessedDelivery) {
    this.deliveryDetailsPageService.finishDelivery(processedDelivery);

    // 1. store finish/driver data in dexie db
    // 2. mark as UPLOADABLE
    //  2a. immediately try to upload (?)
    // 3. make sure local delivery is marked as isDelivered, in order to show state on overview
    // 4. switch to tour overview, in order to choose next delivery

    // what is needed for finishing

    // storage in dexie local db
    // -> service to store locally
    // -> dexie table to store locally

    // sync to server
    // -> service to push to server
    // -> mutation to store data, on success: set flag in local dexie db. otherwise try again every X. later: service worker to get notified whenever app is back online after being offline

    // displaying delivery status, ie DELIVERED + uploaded

    // photo handling
    // -> compress photo size
    // -> store photo locally in dexie as base64
    // -> service to push photo to server
    // -> display state of photo upload. + how many photos a deliv as

    // TOOO: proper redirection
    // Redirect to tour page and flash the finished delivery success-green
    this.router.navigate([
      MOBILE_ROUTE_PATHS.DELIVERY_DONE_DETAILS + '/' + processedDelivery.deliveryId,
    ]);
  }

  titelizeNameString(value: string): string {
    return value.replace(
      /(^|\s|-)(\p{L})/gu,
      (match, prefix, char) => prefix + char.toUpperCase()
    );
  }

  onSignaturePersonNameChange(value: string): void {
    this.signaturePersonName = this.titelizeNameString(value).trim();
  }
}

// check: active tab index während wizard steps durchgangen werden

// num pad +/- und custom tab-order. innerhalb mengen und retourenspalte springen. nicht in dom-order
// aktuellen fokus besonders stark  hervorheben, ggf noch hintergrund
// retouren mit 0 initialisieren
// nach bestätigung einer mengeneingabe (row), den input besonders hervorheben, zB grüne outline
// SPALTE (menge/retoure), in dem keypad gerade aktiv ist, besonders hervorheben

