import * as Types from './_types.generated';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type InspectMobileMutationVariables = Types.Exact<{
  dataString: Types.Scalars['String']['input'];
}>;


export type InspectMobileMutation = { __typename?: 'Mutation', inspect: boolean };

export const InspectMobileDocument = gql`
    mutation InspectMobile($dataString: String!) {
  inspect(dataString: $dataString)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class InspectMobileService extends Apollo.Mutation<InspectMobileMutation, InspectMobileMutationVariables> {
    override document = InspectMobileDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }