import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { Router } from '@angular/router';
import { InputComponent } from '../../core/components/input/input.component';
import { FormsModule } from '@angular/forms';
import { catchError, tap, throwError } from 'rxjs';
import { LoginService } from '../../graphql/auth-operations.generated';
import { AuthService } from '../../shared/services/auth/auth.service';
import { MOBILE_ROUTE_PATHS } from '../../app.routes';
import { APP_VERSION } from '@prf/shared/domain';

@Component({
  selector: 'prf-login',
  templateUrl: './login.page.component.html',
  styleUrls: ['./login.page.component.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, InputComponent, FormsModule],
})
export class LoginPageComponent {
  protected readonly appVersion = APP_VERSION;

  private router = inject(Router);
  private loginService = inject(LoginService);
  private authService = inject(AuthService);

  constructor() {}

  userName: string = '';
  userPassword: string = '';

  hasLoginError = false;

  // TODO: proper form validation
  onSubmitLoginForm(form: any): void {
    if (this.userName && this.userPassword) {
      this.hasLoginError = false;
      this.loginService
        .mutate({
          username: this.userName,
          password: this.userPassword,
        })
        .pipe(
          tap(({ data }) => {
            if (data?.login) {
              this.authService.setSessionLoginData(data.login);
              this.router.navigate([MOBILE_ROUTE_PATHS.VEHICLE_SELECTION]);
            }
          }),
          catchError((error) => {
            console.error('Error logging user in:', error);
            this.hasLoginError = true;
            // TODO: show inline error message
            return throwError(error);
          }),
        )
        .subscribe();
    }
  }

}

// TODO: handle forbidden response => redirect to login, like in client-hq



// // orientation.service.ts
// import { Injectable } from '@angular/core';
// import { fromEvent, Observable } from 'rxjs';
// import { map } from 'rxjs/operators';
//
// @Injectable({
//   providedIn: 'root'
// })
// export class OrientationService {
//
//   constructor() { }
//
//   getOrientation(): Observable<string> {
//     return fromEvent<DeviceOrientationEvent>(window, 'deviceorientation').pipe(
//       map(event => {
//         const isPortrait = Math.abs(event.beta!) > Math.abs(event.gamma!);
//         return isPortrait ? 'PORTRAIT' : 'LANDSCAPE';
//       })
//     );
//   }
// }


//// usage

// // app.component.ts
// import { Component, OnDestroy, OnInit } from '@angular/core';
// import { Subscription } from 'rxjs';
// import { OrientationService } from './orientation.service';
//
// @Component({
//   selector: 'app-root',
//   template: `<h1>Current Orientation: {{ orientation }}</h1>`
// })
// export class AppComponent implements OnInit, OnDestroy {
//   orientation: string = '';
//   private orientationSubscription!: Subscription;
//
//   constructor(private orientationService: OrientationService) {}
//
//   ngOnInit() {
//     this.orientationSubscription = this.orientationService.getOrientation().subscribe(
//       orientation => {
//         this.orientation = orientation;
//         console.log(orientation);
//       }
//     );
//   }
//
//   ngOnDestroy() {
//     this.orientationSubscription.unsubscribe();
//   }
// }
