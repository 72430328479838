import * as Types from './_types.generated';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type FinishDriverDeliveryMutationVariables = Types.Exact<{
  input: Types.FinishDriverDeliveryInput;
}>;


export type FinishDriverDeliveryMutation = { __typename?: 'Mutation', finishDriverDelivery: boolean };

export type SubmitDeliveryPhotoMutationVariables = Types.Exact<{
  input: Types.SubmitDeliveryPhotoInput;
}>;


export type SubmitDeliveryPhotoMutation = { __typename?: 'Mutation', submitDeliveryPhoto: boolean };

export const FinishDriverDeliveryDocument = gql`
    mutation FinishDriverDelivery($input: FinishDriverDeliveryInput!) {
  finishDriverDelivery(finishDriverDeliveryInput: $input)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class FinishDriverDeliveryService extends Apollo.Mutation<FinishDriverDeliveryMutation, FinishDriverDeliveryMutationVariables> {
    override document = FinishDriverDeliveryDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SubmitDeliveryPhotoDocument = gql`
    mutation SubmitDeliveryPhoto($input: SubmitDeliveryPhotoInput!) {
  submitDeliveryPhoto(submitDeliveryPhotoInput: $input)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SubmitDeliveryPhotoService extends Apollo.Mutation<SubmitDeliveryPhotoMutation, SubmitDeliveryPhotoMutationVariables> {
    override document = SubmitDeliveryPhotoDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }