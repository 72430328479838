import { Injectable } from '@angular/core';
import { LogoutService } from '../../../graphql/auth-operations.generated';
import { finalize, tap } from 'rxjs';
import { ILoginResponse, SESSION_STORAGE_KEYS } from '@prf/shared/domain';

// TODO: Extract/abstract into shared, reuse in client-hq and perfo-mobile.

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private logoutService: LogoutService) {}

  getSessionUsername(): string {
    return localStorage.getItem(SESSION_STORAGE_KEYS.USERNAME) || '- kein Benutzername -';
  }

  setSessionLoginData({ expiry, role, username }: ILoginResponse): void {
    localStorage.setItem(SESSION_STORAGE_KEYS.EXPIRY, expiry.toString());
    localStorage.setItem(SESSION_STORAGE_KEYS.ROLE, role);
    localStorage.setItem(SESSION_STORAGE_KEYS.USERNAME, username);
  }

  private clearLocalstorageAuthValues(): void {
    localStorage.removeItem(SESSION_STORAGE_KEYS.EXPIRY);
    localStorage.removeItem(SESSION_STORAGE_KEYS.ROLE);
    localStorage.removeItem(SESSION_STORAGE_KEYS.USERNAME);
  }

  logout(skipLogoutRequest: boolean = false): void {
    this.clearLocalstorageAuthValues();
    if (skipLogoutRequest) {
      window.location.href = '/login';
      return;
    }
    this.logoutService
      .mutate()
      .pipe(
        tap((response) => {
          if (response?.data?.logout) {
            console.log('logout SUCCESS - ');
            // show toast success
          } else {
            console.error('logout FAIL - ');
            // show toast error logout fail
          }
        }),
        // TODO: catch error (ie no connectivity)
        finalize(() => {
          // Resets internal state.
          window.location.href = '/login';
        }), // Navigate to login after the operation completes
      )
      .subscribe();
    // TODO: Refactor: Abstract navigation routes/actions into service.
  }
}
