<prf-content-toolbar-header [title]="formattedHeaderDate ? formattedHeaderDate : ''" />

<ion-content>
  <ion-refresher slot="fixed" (ionRefresh)="handleRefreshDeliveries($event)">
    <ion-refresher-content
      pullingIcon="chevron-down-circle-outline"
      pullingText="Runterziehen zum Aktualisieren"
      refreshingSpinner="dots"
      refreshingText="Lieferungen werden aktualisiert..."
    >
    </ion-refresher-content>
  </ion-refresher>

  <ng-container *ngIf="deliveryMarketsTourPageService.deliveryTour$ | async as deliveryTour">
    <div class="ion-padding pt-1 pb-0">
      <p>Anzahl der Märkte: <b>{{ deliveryTour.numMarkets }}</b></p>
      @if (deliveryTour.isToday) {
        <p>Wählen Sie den zu beliefernden Markt aus.</p>
      } @else {
        <p class="flex">
          <ion-icon name="information-circle-outline" size="large" class="mr-2"></ion-icon>
          Diese Tour ist nicht die heutige und zeigt daher nur eine Übersicht der Märkte.
        </p>
      }
    </div>

    <!-- TODO: Extract into delivery card component -->
    <ng-container *ngFor="let delivery of deliveryTour.deliveries; let index = index;">
      <ion-card style="display: flex;"
                class="outline"
                [disabled]="refreshInProgress"
                [class.outline-delivered]="delivery.isDelivered"
                (click)="onClickDeliveryItem(delivery, deliveryTour.isToday)"
      >
        <ion-card-header style="flex-grow: 1; padding-top: 12px;">
          <ion-card-title *ngIf="delivery.isDelivered"
                          style="display: flex; align-items: center;"
                          class="prf-color-success">
            <ion-icon name="checkmark-circle-sharp" class="mr-1"></ion-icon>
            Ausgeliefert
          <!-- <ion-icon name="cloud-done" class="ml-1 mr-1"></ion-icon>-->
          <!-- <ion-icon name="cloud-done-outline" class="ml-1"></ion-icon>-->
          </ion-card-title>
          <ion-card-title>{{ index + 1 }}. {{ delivery.marketName }}</ion-card-title>
          <ion-card-subtitle>
            <span style="font-size: 1.3em; color: black;">
              {{ delivery.marketAddress }}
            </span>
          </ion-card-subtitle>
          <ion-card-subtitle>
            <span style="font-size: 1.3em; color: black;">
              LS {{ delivery.deliverySlipNumber }}
            </span>
          </ion-card-subtitle>
        </ion-card-header>
        <div *ngIf="deliveryTour.isToday"
             style="display: flex; justify-content: center; align-items: center;"
             class="pr-1 icon-details">
          <ion-icon name="chevron-forward-outline" size="large"></ion-icon>
        </div>
      </ion-card>
    </ng-container>
  </ng-container>

  <!--  TODO: empty array view-->

</ion-content>
