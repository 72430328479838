import {
  HttpEvent,
  HttpEventType,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable, tap } from 'rxjs';
import { inject, Injectable } from '@angular/core';
import { AuthService } from '../services/auth/auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private authService = inject(AuthService);

  constructor() {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap((event) => {
        if (event.type === HttpEventType.Response) {
          // Check for error codes in the GraphQL response
          if (event.body.errors) {
            const authErrors = event.body.errors.filter(
              (error: any) => error.extensions && error.extensions.code === 'FORBIDDEN',
            );
            if (authErrors.length > 0) {
              // DO LOGOUT via auth service -> localStorage
              this.authService.logout(true);
            }
          }
        }
      }),
    );
  }
}
