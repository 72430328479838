import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { MOBILE_ROUTE_PATHS } from '../../app.routes';
import { Router } from '@angular/router';
import { DeliveryDetailsPageService } from '../delivery-details/delivery-details-page.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { DeliveryTour, IDriverDelivery } from '@prf/shared/domain';
import { DeliveryMarketsTourPageService } from '../delivery-markets-tour/delivery-markets-tour-page.service';

@Component({
  selector: 'prf-delivery-done-details',
  templateUrl: './delivery-done-details.page.html',
  styleUrls: ['./delivery-done-details.page.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule],
})
export class DeliveryDoneDetailsPage {
  private router = inject(Router);
  protected deliveryDetailsPageService = inject(DeliveryDetailsPageService);
  protected deliveryMarketsTourPageService = inject(DeliveryMarketsTourPageService);
  private currentDeliveryTourDate: string | null = null;
  currentDelivery: IDriverDelivery | null = null;

  constructor() {
    this.deliveryDetailsPageService.currentDeliveryId$
      .pipe(takeUntilDestroyed())
      .subscribe((deliveryId: number | null) => {
        // this.currentDeliveryId = deliveryId;
      });

    this.deliveryDetailsPageService.delivery$
      .pipe(takeUntilDestroyed())
      .subscribe(delivery => {
        this.currentDelivery = delivery;
      });

    this.deliveryMarketsTourPageService.deliveryTour$
      .pipe(takeUntilDestroyed())
      .subscribe((deliveryTour: DeliveryTour) => {
        this.currentDeliveryTourDate = deliveryTour.date;
      });
  }

  onClickBackToTour() {
    this.goToCurrentDeliveryTourPage();
  }

  private goToCurrentDeliveryTourPage(): void {
    this.router.navigate([
      MOBILE_ROUTE_PATHS.DELIVERY_MARKETS_TOUR + '/' + this.currentDeliveryTourDate
    ]);
  }
}
