<!--<ion-content [fullscreen]="true">-->
<ion-content>
  <div class="top-background">
    <img src="/assets/img/paldo-logo.svg" alt="Logo" loading="eager" />
  </div>

  <div class="ion-padding">
    <ion-card>
      <ion-card-content>
        <form #loginForm="ngForm" id="loginForm" (ngSubmit)="onSubmitLoginForm(loginForm)">
          <div>
            <prf-input label="Benutzername"
                       placeholder="Geben Sie Ihren Benutzernamen ein"
                       name="username"
                       [(ngModel)]="userName"
                       [required]="true"
            ></prf-input>
          </div>
          <div class="mt-2">
            <prf-input label="Passwort"
                       placeholder="Geben Sie Ihr Passwort ein"
                       name="password"
                       [(ngModel)]="userPassword"
                       [required]="true"
                       type="password"
            ></prf-input>
          </div>
        </form>
        <div class="button-container mt-4">
          <ion-button form="loginForm"
                      type="submit"
                      [disabled]="!userName || !userPassword"
          >
            Anmelden
          </ion-button>
        </div>
      </ion-card-content>
    </ion-card>
    <div class="version-footer mt-1">
      <span class="version">Version {{ appVersion }}</span>
    </div>
    <div *ngIf="hasLoginError" class="ml-2 mt-4 ion-text-center">
      <ion-text color="danger">Fehler bei der Anmeldung. <br />Bitte prüfen Sie Ihre Eingaben.</ion-text>
    </div>
  </div>
</ion-content>
