import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { InputComponent } from '../../../../../../core/components/input/input.component';
import { FormsModule } from '@angular/forms';
import { IDriverDeliveryProduct } from '@prf/shared/domain';
import {
  ProductInputChangeEvent,
  QuantityType,
} from '../product-amount-grid/product-amount-grid.component';

@Component({
  selector: 'prf-amount-number-pad',
  standalone: true,
  imports: [CommonModule, IonicModule, InputComponent, FormsModule],
  templateUrl: './amount-number-pad.component.html',
  styleUrls: ['./amount-number-pad.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AmountNumberPadComponent implements OnChanges {
  @Input({ required: true })
  item!: IDriverDeliveryProduct;

  @Input({ required: true })
  quantityType!: QuantityType;

  @Output()
  numberChange = new EventEmitter<ProductInputChangeEvent>();

  @Output()
  confirm = new EventEmitter<void>();

  @Output()
  next = new EventEmitter<void>();

  @Output()
  minimize = new EventEmitter<void>();

  private shouldOverwrite = true;

  ngOnChanges(changes: SimpleChanges): void {
    // Reset flag, when touching input manually, ie via grid input focus, and not via number-pad actions.
    if (changes['item'] || changes['quantityType']) {
      this.resetOverwriteFlag();
    }
    if (changes['quantityType']) {
      this.resetOverwriteFlag();
    }
  }

  onClickPlus(): void {
    // TODO: min max
    // TODO: initialize actualQuantity prior to this, so that a ||0 is not necessary.
    // this.item.actualQuantity = Number((this.item.actualQuantity || 0)) + 1;
    // this.numberChange.emit(this.item.actualQuantity);
    this.numberChange.emit({
      item: this.item,
      quantityType: this.quantityType,
      value: Number(this.item[this.quantityType]) + 1,
    });
  }

  onClickMinus(): void {
    // TODO: min max
    // this.item.actualQuantity = Number((this.item.actualQuantity || 0)) - 1;
    // this.numberChange.emit(this.item.actualQuantity);

    this.numberChange.emit({
      item: this.item,
      quantityType: this.quantityType,
      value: Math.max(Number(this.item[this.quantityType]) - 1, 0),
    });
  }

  onClickNumber(value: number | null): void {
    // IMMER gesamte Zahl überschreiben, d.h. [5] -input:61- wird zu [61] und nicht [561]
    let newValue;

    if (this.shouldOverwrite || value === null) {
      newValue = value ?? 0;
      this.shouldOverwrite = false; // Subsequent numbers will append
    } else {
      const currentValue = this.item[this.quantityType]?.toString() ?? '';
      newValue = parseInt(`${currentValue}${value}`, 10);
    }

    this.numberChange.emit({
      item: this.item,
      quantityType: this.quantityType,
      value: newValue ?? 0,
    });
  }

  onClickConfirm(): void {
    this.confirm.emit();
    this.resetOverwriteFlag();
  }

  onClickNext(): void {
    this.next.emit();
    this.resetOverwriteFlag();
  }

  onClickMinimize() {
    this.minimize.emit();
    this.resetOverwriteFlag();
  }

  private resetOverwriteFlag(): void {
    this.shouldOverwrite = true;
  }
}
