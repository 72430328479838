<prf-content-toolbar-header title="Aktuelle Lieferwoche" />
<!-- TODO: Add KW to title: Lieferwoche KW 7-->

<!-- Show user avatar/settings icon inside end-slot? -->
<ion-content>

  <ion-refresher slot="fixed" (ionRefresh)="handleRefreshDeliveries($event)">
    <ion-refresher-content
      pullingIcon="chevron-down-circle-outline"
      pullingText="Runterziehen zum Aktualisieren"
      refreshingSpinner="dots"
      refreshingText="Lieferungen werden aktualisiert..."
    >
    </ion-refresher-content>
  </ion-refresher>

  <!--  TODO: show current drivers name -->
  <ng-container *ngIf="deliveriesWeekPageService.deliveryTours$ | async as deliveryTours">
    <div class="ion-padding">
      <!--  TODO: timestamp of last refresh -->
<!--      <div class="mb-2 text-center">Zuletzt aktualisiert: TIMESTAMP</div>-->
      <div *ngIf="refreshInProgress || (deliveryTours && deliveryTours.length > 0)" class="pt-1">Wählen Sie den Liefertag aus.</div>
      <div *ngIf="!refreshInProgress && (deliveryTours?.length === 0)" class="pt-1">Aktuell liegen keine Lieferungen vor.</div>
    </div>

    <ng-container *ngFor="let tour of deliveryTours">
      <!--    TODO: HEUTE um 90° gedreht links anzeigen-->
      <ion-card [class.tour-today]="tour.isToday"
                style="display: flex;"
                class="outline"
                [disabled]="refreshInProgress"
                (click)="onClickWeekdayTour(tour)"
      >
        <ion-card-header style="flex-grow: 1;">
          <ion-card-title>{{ formatDate(tour.date) }}</ion-card-title>
          <ion-card-subtitle>{{ tour.numMarkets }} {{ tour.numMarkets > 1 ? 'Märkte' : 'Markt' }}</ion-card-subtitle>
        </ion-card-header>
        <div style="display: flex; justify-content: center; align-items: center;" class="pr-1">
          <!--          <ng-container *ngIf="tour.canNavigate">-->
          <ion-icon name="chevron-forward-outline" size="large"></ion-icon>
          <!--          </ng-container>-->
        </div>
      </ion-card>
    </ng-container>

  </ng-container>

</ion-content>
