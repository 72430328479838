import { inject, Injectable } from '@angular/core';
import { VehicleDataService } from '../../shared/services/data/vehicle-data.service';
import { IVehicle } from "@prf/shared/domain";
import { from, Observable } from "rxjs";

@Injectable({
  providedIn: 'root',
})
export class VehicleSelectionPageService {
  private vehicleDataService = inject(VehicleDataService);

  public vehicles$: Observable<IVehicle[]> = from(this.vehicleDataService.vehicles$);

  constructor() {}
}
