import { Routes } from '@angular/router';

export const MOBILE_ROUTE_PATHS = {
  LOGIN: 'login',
  VEHICLE_SELECTION: 'vehicle-selection',
  DELIVERIES_WEEK: 'deliveries-week',
  DELIVERY_MARKETS_TOUR: 'delivery-markets-tour',

  DELIVERY_DETAILS: 'delivery-details',
  MARKET_INFO: 'market-info',
  DELIVERY_SLIP: 'delivery-slip',
  PHOTOS: 'photos',
  MESSAGES: 'messages',

  DELIVERY_DONE_DETAILS: 'delivery-done-details',
  DEFAULT: '',
};

export const routes: Routes = [
  // Main routes
  {
    path: MOBILE_ROUTE_PATHS.LOGIN,
    loadComponent: () =>
      import('./pages/login/login.page.component').then(
        (m) => m.LoginPageComponent
      ),
  },
  {
    path: MOBILE_ROUTE_PATHS.VEHICLE_SELECTION,
    loadComponent: () =>
      import('./pages/vehicle-selection/vehicle-selection.page.component').then(
        (m) => m.VehicleSelectionPageComponent
      ),
  },
  {
    path: MOBILE_ROUTE_PATHS.DELIVERIES_WEEK,
    loadComponent: () =>
      import('./pages/deliveries-week/deliveries-week.page.component').then(
        (m) => m.DeliveriesWeekPageComponent
      ),
  },
  {
    path: MOBILE_ROUTE_PATHS.DELIVERY_DETAILS + '/:deliveryId',
    loadComponent: () =>
      import('./pages/delivery-details/delivery-details.page.component').then(
        (m) => m.DeliveryDetailsPageComponent
      ),
    children: [
      {
        path: '', // Default redirect
        redirectTo: MOBILE_ROUTE_PATHS.MARKET_INFO,
        pathMatch: 'full',
      },
      {
        path: MOBILE_ROUTE_PATHS.MARKET_INFO,
        loadComponent: () =>
          import(
            './pages/delivery-details/tabs/market-info/delivery-market-info-tab.component'
            ).then((m) => m.DeliveryMarketInfoTabComponent),
      },
      {
        path: MOBILE_ROUTE_PATHS.DELIVERY_SLIP,
        loadComponent: () =>
          import(
            './pages/delivery-details/tabs/delivery-slip/delivery-slip-tab.component'
            ).then((m) => m.DeliverySlipTabComponent),
      },
      {
        path: MOBILE_ROUTE_PATHS.PHOTOS,
        loadComponent: () =>
          import(
            './pages/delivery-details/tabs/photos/delivery-photos-tab.component'
          ).then((m) => m.DeliveryPhotosTabComponent),
      },
      {
        path: MOBILE_ROUTE_PATHS.MESSAGES,
        loadComponent: () =>
          import('./pages/delivery-details/tabs/messages/delivery-messages-tab.component').then(
            (m) => m.DeliveryMessagesTabComponent
          ),
      },
    ],
  },
  {
    path: MOBILE_ROUTE_PATHS.DELIVERY_DONE_DETAILS + '/:deliveryId',
    loadComponent: () =>
      import('./pages/delivery-done-details/delivery-done-details.page').then(
        (m) => m.DeliveryDoneDetailsPage
      ),
  },
  {
    path: MOBILE_ROUTE_PATHS.DELIVERY_MARKETS_TOUR + '/:date',
    loadComponent: () =>
      import(
        './pages/delivery-markets-tour/delivery-markets-tour.page.component'
      ).then((m) => m.DeliveryMarketsTourPageComponent),
  },

  // Default route
  {
    path: MOBILE_ROUTE_PATHS.DEFAULT,
    redirectTo: MOBILE_ROUTE_PATHS.LOGIN,
    pathMatch: 'full',
  },
];

// TODO
// add @angular/pwa

// storage via ionic plugin?
// https://github.com/ionic-team/ionic-storage
// OR: custom usage of Dexie oÄ

// When using *ngFor with Ionic components, we recommend using Angular's trackBy option
// <ion-item *ngFor="let item of items; trackBy:trackItems">
// https://ionicframework.com/docs/angular/performance

// IONIC RESOURCES

// ionic standalone starter: list
// https://github.com/ionic-team/starters/blob/main/angular-standalone/official/list/src/app/app.routes.ts

// conference app, using gmaps among others
// https://github.com/ionic-team/ionic-conference-app/tree/main

// foto gallery app, incl photo service and ionic pwa elements
// tutorial: https://ionicframework.com/docs/angular/your-first-app
// repo:     https://github.com/ionic-team/tutorial-photo-gallery-angular

// ionfits app, incl login screen, camera app taking (with centered FAB), settings view
// web preview: https://ionifits.ionic.io/
// repo         https://github.com/ionic-team/ionifits

// info: postgres uses format "yyyy-mm-dd" for dates

// workbox example background sync
// file:///W:/Downloads/978-1-4842-4448-7.pdf

// other example, ng with workbox: https://golb.hplar.ch/2018/06/workbox-serviceworker-in-angular-project.html
// !!! https://golb.hplar.ch/2018/12/background-sync-ng.html --- etwas anders, als das refetch/request plugin von workbox - low level backgroundSync stattdessen
// https://github.com/ralscha/blog/tree/master/background-sync/clientng
// https://github.com/ralscha/blog/blob/master/background-sync/clientng/src/sw-sync.js

// https://github.com/webmaxru/pwatter/blob/workbox-step6/dist/sw.js#L193
// https://github.com/webmaxru/pwatter/blob/workbox-step6/src/app/tweet.service.ts
// see: https://developer.chrome.com/docs/workbox/retrying-requests-when-back-online/
// Because BackgroundSyncPlugin hooks into the FetchDidFail plugin's callback, failed requests must be the result of a network failure. Requests that result in a 400 or 500-level error status will not be retried. To retry requests resulting in these types of failures, try adding a FetchDidSucceed plugin to your strategy.
// also: via queue: https://developer.chrome.com/docs/workbox/retrying-requests-when-back-online/#creating-a-queue
// Sadly, testing BackgroundSync is somewhat unintuitive and difficult for a number of
// reasons. One of the best ways to test is by using the following steps:
// 1. Build and run application in production as Service Worker is
// registered.
// 2. Turn off your computer’s network or turn off back-end server,
// which is simple-express-server.js. Please note that you cannot
// use offline in Chrome DevTools as it will only affect requests from
// the page. Service Worker requests will continue to go through.
