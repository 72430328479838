import * as Types from './_types.generated';

import { gql } from 'apollo-angular';
import { AddressFieldsFragmentDoc } from './shared-fragments.generated';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type MyDeliveriesForCurrentWeekQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type MyDeliveriesForCurrentWeekQuery = { __typename?: 'Query', myDeliveriesForCurrentWeek: Array<{ __typename?: 'DeliveryEntity', id: number, deliveryDate: any, deliverySlipNumber: string, status: Types.DeliveryStatus, type: Types.DeliveryType, driverNote: string, vehicleId?: number | null, createdAt?: any | null, updatedAt?: any | null, market?: { __typename?: 'MarketEntity', id: number, marketName: string, contactPhone: string, deliverySlipEmail: string, deliveryAddress: { __typename?: 'AddressEmbeddable', street: string, houseNumber: string, postalCode: string, city: string, country: string } } | null, deliveryProducts?: Array<{ __typename?: 'DeliveryProductEntity', id: number, targetQuantity?: number | null, actualQuantity?: number | null, returnQuantity?: number | null, product: { __typename?: 'ProductEntity', id: number, description: string, productNo: string } }> | null }> };

export const MyDeliveriesForCurrentWeekDocument = gql`
    query MyDeliveriesForCurrentWeek {
  myDeliveriesForCurrentWeek {
    id
    deliveryDate
    deliverySlipNumber
    market {
      id
      marketName
      deliveryAddress {
        ...AddressFields
      }
      contactPhone
      deliverySlipEmail
    }
    deliveryProducts {
      id
      targetQuantity
      actualQuantity
      returnQuantity
      product {
        id
        description
        productNo
      }
    }
    status
    type
    driverNote
    vehicleId
    createdAt
    updatedAt
  }
}
    ${AddressFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class MyDeliveriesForCurrentWeekService extends Apollo.Query<MyDeliveriesForCurrentWeekQuery, MyDeliveriesForCurrentWeekQueryVariables> {
    override document = MyDeliveriesForCurrentWeekDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }