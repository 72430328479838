import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule, IonModal } from '@ionic/angular';
import {
  NgSignaturePadOptions,
  SignaturePadComponent,
  SignaturePadDrawEndEvent,
} from '../signature-pad/signature-pad.component';

export interface SignaturePadBase64Image {
  signatureBase64: string | null;
}

@Component({
  selector: 'prf-signature-pad-modal',
  standalone: true,
  imports: [CommonModule, IonicModule, SignaturePadComponent],
  templateUrl: './signature-pad-modal.component.html',
  styleUrls: ['./signature-pad-modal.component.scss'],
  // TODO: Check for reactivation of changeDetectionStrat
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SignaturePadModalComponent implements OnChanges {
  @Input({ required: true })
  isOpen!: boolean;

  @Input()
  name!: string;

  @Output()
  didDismiss = new EventEmitter<{ detail: { data: SignaturePadBase64Image } }>();

  currentSignatureImageBase64: string | null = null;

  @ViewChild(IonModal) modal!: IonModal;

  @ViewChild(SignaturePadComponent) signaturePadComp!: SignaturePadComponent;

  CANVAS_WIDTH = 380;
  CANVAS_HEIGHT = 670;

  protected signaturePadOptions: NgSignaturePadOptions = {
    minWidth: 3,
    canvasWidth: this.CANVAS_WIDTH,
    canvasHeight: this.CANVAS_HEIGHT,
  };

  ngOnChanges(changes: SimpleChanges): void {
    // TODO: add type safety for usage of isOpen field
    if (changes['isOpen']?.currentValue) {
      this.currentSignatureImageBase64 = null;
    }
  }

  confirm(): void {
    const eventData = { detail: { data: { signatureBase64: this.currentSignatureImageBase64 } } };
    this.didDismiss.emit(eventData);
  }

  onDrawEnd(event: SignaturePadDrawEndEvent): void {
    this.currentSignatureImageBase64 = event.dataURL;
  }

  onClickDeleteSignature(): void {
    this.currentSignatureImageBase64 = null;
    this.signaturePadComp.clear();
  }
}
