import { IEntity } from './entity.interface';
import { IRecipe } from './recipe.interface';

export type ProductVatRate = 7 | 19;

export interface IProduct extends IEntity {
  productNo: string;
  category: string;
  description: string;
  // DNF: pack is used for calculating total amount in KG @ delivery slip.
  // Check if refactoring to number based, unit based attrs should be done.
  pack: string; // 250gr, 375gr, etc. // package / Schale
  ean: string;
  netPrice: number;
  grossPrice: number; // -> This is renamed to Bruttopreis UVP - Used for statistics, ie how much revenue a market made with their product.
  vatRate: number; // TODO: Check for usage of ProductVatRate. However, that type also needs to be generated into the products-operations.generated.ts - otherwise frontend trows a typeerror. see also: ProductEntity
  uvp: number; // Retailer specific
  recipe?: IRecipe | any; // TODO: type fix, for ORM Entity and client
}

// TODO: Refactor: Extract into own "categories" file.
export type ProductCategoryName = 'Antipasti' | 'Salate' | 'Cremes und Dips' | 'Sonderposten';
export const PRODUCT_CATEGORIES_CONFIG: {
  name: ProductCategoryName;
  productNoStartsWith: string;
}[] = [
  {
    name: 'Antipasti',
    productNoStartsWith: '1',
  },
  {
    name: 'Salate',
    productNoStartsWith: '2',
  },
  {
    name: 'Cremes und Dips',
    productNoStartsWith: '3',
  },
  {
    name: 'Sonderposten',
    productNoStartsWith: '9',
  },
];

export const PRODUCT_CATEGORY_OPTIONS: {
  label: ProductCategoryName;
  value: ProductCategoryName;
}[] = PRODUCT_CATEGORIES_CONFIG.map(category => {
  return {
    label: category.name,
    value: category.name
  };
});

// Kategorien - ArtikelNr-System
// zunächst nur hardkodierte Kategorien, keine Entities
// Antipasti 1xx
// Salate 2xx
// Cremes und Dips 3xx
// später ggf weitere, zB Trockenfrüchte 4xx
