const SESSION_STORAGE_KEY_PREFIX = 'prf-';
export const SESSION_STORAGE_KEYS = {
  EXPIRY: SESSION_STORAGE_KEY_PREFIX + 'expiry',
  ROLE: SESSION_STORAGE_KEY_PREFIX + 'role',
  USERNAME: SESSION_STORAGE_KEY_PREFIX + 'username',
} as const;

export interface ILoginResponse {
  expiry: number; // UNIX timestamp
  role: string; // EUserRole
  username: string;
}
