import { Injectable } from '@angular/core';
import { Camera, CameraDirection, CameraResultType, CameraSource, Photo } from '@capacitor/camera';
import loadImage from 'blueimp-load-image';

// TODO: Check for removal.
export interface CapturedPhoto {
  filepath: string;
  webviewPath?: string;
}

@Injectable({
  providedIn: 'root'
})
export class PhotoService {

  // https://www.raymondcamden.com/2018/10/05/storing-retrieving-photos-in-indexeddb

  constructor() { }

  async capturePhoto() {
    try {
      const capturedPhoto = await Camera.getPhoto({
        // TODO: Check change to type.DataUrl
        resultType: CameraResultType.Uri,
        source: CameraSource.Camera,
        // TODO: Galerie-Fotos NICHT erlauben
        direction: CameraDirection.Rear,
        // Probably not working
        quality: 30,
        allowEditing: false,

        // TODO: check if this interfers with custom rotation
        // Not working
        correctOrientation: true, // automatically rotate image up to correct orientation

        // The desired maximum height of the saved image. The aspect ratio is respected.
        // Not working
        width: 1800,
        height: 1800,
      });

      return capturedPhoto;
    } catch (e) {
      return null;
    }
  }

  // TODO: pass filename , ie delviery number oÄ
  async getPhotoData(photo: Photo, isPortraitOrientation: boolean) {
    // console.log('getPhotoData - isPortraitOrientation', isPortraitOrientation);

    const base64Data = await this.readAsBase64(photo, isPortraitOrientation);
    // console.groupCollapsed("Photo base64 string");
    // console.log(base64Data);
    // console.groupEnd();

    // Write the file to the data directory
    const fileName = Date.now() + `.${photo.format}`;
    console.warn('getPhotoData - fileName', fileName);

    // Use webPath to display the new image instead of base64 since it's already loaded into memory
    return {
      filepath: fileName,
      webviewPath: photo.webPath,
      base64Data: base64Data,
    };
  }

  private async readAsBase64(photo: Photo, isPortraitOrientation: boolean) {
    // Fetch the photo, read as a blob, then convert to base64 format
    const response = await fetch(photo.webPath!);
    const blob = await response.blob();

    return await this.convertBlobToBase64(blob, isPortraitOrientation) as string;
  }

  private convertBlobToBase64 = (blob: Blob, isPortraitOrientation: boolean): Promise<string> => {
    return new Promise((resolve, reject) => {
      // Use the "loadImage" function from the "blueimp-load-image" library
      loadImage(
        blob,
        (canvas: any) => {
          // Check if the operation returned a canvas element
          if (canvas.type === 'error') {
            reject('Error loading image');
          } else {
            // Convert canvas to data URL
            resolve(canvas.toDataURL());
          }
        },
        {
          // Orientation codes see https://github.com/blueimp/JavaScript-Load-Image/blob/5d34ed4ce3472ecb22d920c66cc4d7604526ff24/js/load-image-orientation.js#L284
          // orientation: isPortraitOrientation ? 6 : undefined,  // 90° cw
          // orientation: isPortraitOrientation ? 6 : 3,  // 90° cw
          maxWidth: 1900,
          maxHeight: 1900,
          canvas: true
        }
      );
    });
  }

}


// IMAGE COMPRESSION
// https://gist.github.com/ORESoftware/ba5d03f3e1826dc15d5ad2bcec37f7bf

// other
// https://stackoverflow.com/a/14672943
