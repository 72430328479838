import { Component, ChangeDetectionStrategy, forwardRef, Input, Output, EventEmitter } from "@angular/core";
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, FormsModule } from '@angular/forms';

@Component({
  selector: 'prf-input',
  standalone: true,
  imports: [CommonModule, IonicModule, FormsModule],
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputComponent),
      multi: true
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputComponent implements ControlValueAccessor {
  @Input() label!: string;
  @Input() placeholder!: string;
  @Input() type: string = 'text';
  @Input() readonly: boolean = false; // used to prevent mobile keyboard, ie iOS, from auto open upon input focus.
  @Input() required: boolean = false;
  @Input() maxlength!: number;
  @Input() disabled: boolean = false;

  @Input()
  isFocussed: boolean = false;

  @Output()
  hasFocus = new EventEmitter<void>();

  private innerValue: any;

  private onChange = (_: any) => {};
  private onTouched = () => {};

  get value(): any {
    return this.innerValue;
  }

  set value(v: any) {
    if (v !== this.innerValue) {
      this.innerValue = v;
      this.onChange(v);
    }
  }

  writeValue(value: any): void {
    this.innerValue = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    // Handle the disabled state if required
  }
}
