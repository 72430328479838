import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { ContentToolbarHeaderComponent } from '../../shared/components/content-toolbar-header/content-toolbar-header.component';
import { Router } from '@angular/router';
import dayjs from 'dayjs';import 'dayjs/locale/de';
import { MOBILE_ROUTE_PATHS } from '../../app.routes';
import { DeliveriesWeekPageService } from './deliveries-week-page.service';
import { DeliveryTour } from '@prf/shared/domain';
import { forkJoin, from, timer } from 'rxjs';

dayjs.locale('de');

// TODO: Extract into util
export const isToday = (deliveryDate: string): boolean => {
  const today = dayjs().format('YYYY-MM-DD');
  return deliveryDate === today;
};

@Component({
  selector: 'prf-deliveries-week',
  templateUrl: './deliveries-week.page.component.html',
  styleUrls: ['./deliveries-week.page.component.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, ContentToolbarHeaderComponent],
})
export class DeliveriesWeekPageComponent {
  private router = inject(Router);

  refreshInProgress = false;

  protected deliveriesWeekPageService = inject(DeliveriesWeekPageService);

  constructor() {}

  onClickWeekdayTour(deliveryTour: DeliveryTour) {
    // TODO: Refactor: Cleanup route paths, param
    this.router.navigate([MOBILE_ROUTE_PATHS.DELIVERY_MARKETS_TOUR + '/' + deliveryTour.date]);
  }

  // TODO: EXTRACT and calc only once on init
  formatDate(date: string | Date): string {
    // info: postgres uses format "yyyy-mm-dd" for dates
    return dayjs(date).format('dddd, DD.MM.YYYY');
  }

  // MERGE with delivery markets tour page... extract shared method and pass event
  async handleRefreshDeliveries(event: any): Promise<void> {
    // TODO: check for internet connection ON
    // TODO: show msg, if no internet available

    // TODO: set refresh in progress indicator -- probably globally via service...
    // -> show ion-skeletons while refresh is in progress

    // TODO: add same refresher to delivery-markets-tour
    // extract into own standalone comp?

    this.refreshInProgress = true;

    if (navigator.onLine) {
      forkJoin([
        // TODO: check whether to add
        // from(this.deliveriesWeekPageService.checkForPendingDeliveriesToBeSynced()),
        // ^ not yet returning Promise

        from(this.deliveriesWeekPageService.refreshDeliveries()),
        from(this.deliveriesWeekPageService.checkForPendingPhotosToBeSynced()),
        timer(1000), // Show refresh indication for at least 1 second (even if req is quicker).
      ]).subscribe(() => {
        event.target.complete();
        this.refreshInProgress = false;
      });
    } else {
      event.target.complete();
      this.refreshInProgress = false;
      setTimeout(() => {
        window.alert("Aktualisieren nicht möglich. Aktuell liegt keine Internetverbindung vor.")
      }, 250);
    }
  }
}
