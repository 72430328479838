<prf-content-toolbar-header title="Fahrzeug wählen" />

<ion-content [fullscreen]="true">
  <div id="container">

    <!-- TODO: Truck icon -->

    <div class="ion-padding">
      <h3>Bitte wählen Sie das Fahrzeug aus, welches Sie für die heutige Tour nutzen.</h3>

      <div class="mt-4" *ngIf="vehicleSelectionPageService.vehicles$ | async as vehicles">
        <!-- Typeahead comp hier auch denkbar, inkl. Suche: https://ionicframework.com/docs/api/select#typeahead-component -->
        <!-- TODO: remember last used vehicle. postfix option label with "(zuletzt)" and push to top -->

        <!--        custom action sheet for selection: https://ionicframework.com/docs/api/modal#custom-dialogs -->
        <ion-select label="Fahrzeug auswählen"
                    fill="outline"
                    cancelText="Abbrechen"
                    okText="OK"
                    color="primary"
                    class="hide-cancel"
                    [(ngModel)]="selectedVehicle"
        >
          <ion-select-option *ngFor="let vehicle of vehicles"
                             [value]="vehicle.id">
            {{ vehicle.licensePlate }}
          </ion-select-option>
        </ion-select>
      </div>

      <!-- CSS KENNZEICHEN: https://sedna-soft.de/articles/kennzeichen/-->
    </div>
  </div>
</ion-content>

<ion-footer>
  <ion-toolbar color="dark">
    <div class="ion-padding">
      <div class="flex align-items-center">
        <i class="pi pi-info-circle" style="font-size: 1.35em;"></i>
        <!--        <p class="pl-3 font-italic">Ich bestätige hiermit, dass das von mir gewählte Fahrzeug alle gesetzlichen Vorschriften erfüllt, in technisch einwandfreiem Zustand ist und für den Lieferbetrieb geeignet ist.</p>-->
        <p class="pl-3 font-italic">Ich bestätige hiermit, dass das von mir gewählte Fahrzeug ordnungsgemäß gewartet
          wurde, alle nötigen Sicherheitschecks bestanden hat und für den heutigen Liefereinsatz bereit ist.</p>
      </div>

      <!-- TODO: Disabled if no vehicle is selected -->
      <!-- TODO: Remember last used vehicle -->
      <ion-button expand="block"
                  [disabled]="!selectedVehicle"
                  (click)="onClickConfirmVehicle()"
      >
        <ion-icon slot="start" name="checkmark"></ion-icon>
        Bestätigen
      </ion-button>
    </div>
  </ion-toolbar>
</ion-footer>
