import { Pipe, PipeTransform } from '@angular/core';
import dayjs from 'dayjs';

// TODO: Extract into shared pipes for client and mobile
@Pipe({
  name: 'prfDate',
  pure: true,
  standalone: true,
})
export class FormatDatePipe implements PipeTransform {
  transform(value: string | Date, format: string = 'DD.MM.YYYY'): string {
    if (!value || !dayjs(value).isValid()) {
      console.error("Invalid date passed: ", value);
      return "Fehlerhaftes Datum";
    }
    return dayjs(value).format(format);
  }
}
