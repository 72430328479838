import { Injectable } from '@angular/core';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import weekday from 'dayjs/plugin/weekday';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import isoWeek from 'dayjs/plugin/isoWeek';
import Dexie, { liveQuery, Table } from 'dexie';
import { IDriverDelivery, IProcessedDelivery, IVehicle } from '@prf/shared/domain';
import { Observable } from 'rxjs';

export enum LocalDeliveryDataSyncStatus {
  Pending = 'PENDING', // Waiting to be uploaded
  InProgress = 'IN_PROGRESS', // Currently uploading
  // Note: This isn't the same as DeliveryStatus.COMPLETED - should be refactored to avoid confusion.
  Completed = 'COMPLETED', // Successfully uploaded
  Failed = 'FAILED', // Upload failed
}

// TODO:  rename this to "DriverProcDeliv or similar, and implement regular IProcessedDelivery from shared dir
export interface ProcessedDelivery extends IProcessedDelivery {
  deliveryId: number; // Also acts as main dexie table id
  signatureImage: string; // base64
  deliveryProducts: object[]; // TODO: Type
  syncStatus: LocalDeliveryDataSyncStatus;
}

// TODO: check if title: string, needs to be added
export interface DeliveryPhoto {
  id?: number; // DexieID
  deliveryId: number;
  base64Data: string; // base64 string format
  timestamp: Date;
  syncStatus: LocalDeliveryDataSyncStatus;
}

const DEXIE_DB_NAME = 'PerfoMobileLocalDb';

@Injectable({
  providedIn: 'root',
})
export class AppIndexedDb extends Dexie {
  private vehiclesTable!: Table<IVehicle, number>;
  private deliveriesTable!: Table<IDriverDelivery, number>;
  private processedDeliveriesTable!: Table<ProcessedDelivery, number>;
  private deliveryPhotoTable!: Table<DeliveryPhoto, number>;

  // DRIVER ENTRIES, own table: one row, that has a FK to a delivery, and then, inside that table/row there is all submittable data from the driver.
  // photos and messages separately, because they can be updated post-delivery-completion

  vehicles$: Observable<IVehicle[]> = liveQuery(() =>
    this.allDbVehicles(),
  ) as unknown as Observable<IVehicle[]>;

  deliveries$: Observable<IDriverDelivery[]> = liveQuery(() =>
    this.allDbDeliveries(),
  ) as unknown as Observable<IDriverDelivery[]>;

  processedDeliveries$: Observable<ProcessedDelivery[]> = liveQuery(() =>
    this.allProcessedDeliveries(),
  ) as unknown as Observable<ProcessedDelivery[]>;

  constructor() {
    super(DEXIE_DB_NAME);

    // TODO: check for necessity
    dayjs.locale('de');
    dayjs.extend(utc);
    dayjs.extend(weekday);
    dayjs.extend(weekOfYear);
    dayjs.extend(isoWeek);

    // TODO: DEV-REMOVE: only used for DEV.
    // this.deleteDbIfExists();
    this.version(1).stores({
      vehiclesTable: 'id, licensePlate, vehicleModel',
      deliveriesTable:
        'id, marketId, marketName, marketAddress, driverId, vehicleId, deliveryDate, type, status, driverNote',

      // Data entered by driver, ie. information about delivered deliveries.
      // TODO: add vehicleId - store inside processedDelivery
      processedDeliveriesTable:
        // deliveryId acts as primaryKey, as there can only be one processedDelivery per actual (backend) delivery.
        'deliveryId, messageFromMarket, signatureName, signatureImage, deliveredAtMarketDate, deliveryProducts, syncStatus',

      // (!!!)
      // TODO: check: "imageData" is called base64 data by now -- migrate?
      deliveryPhotoTable: '++id, deliveryId, imageData, timestamp, syncStatus',
    });

    // https://dexie.org/docs/Dexie/Dexie.on.populate
    // this.on('populate', () => this.populate());
  }

  clearVehicles() {
    return this.vehiclesTable.clear();
  }

  clearDeliveries() {
    return this.deliveriesTable.clear();
  }

  bulkAddVehicles(vehicles: IVehicle[]) {
    return this.vehiclesTable.bulkAdd(vehicles);
  }

  bulkAddDeliveries(deliveries: IDriverDelivery[]) {
    return this.deliveriesTable.bulkAdd(deliveries);
  }

  allDbVehicles(): Promise<IVehicle[]> {
    return this.vehiclesTable.toArray();
  }

  allDbDeliveries(): Promise<IDriverDelivery[]> {
    return this.deliveriesTable.orderBy('deliveryDate').toArray();
  }

  getPhotosForDelivery(deliveryId: number): Observable<DeliveryPhoto[]> {
    return liveQuery(() => this.queryPhotosByDeliveryId(deliveryId)) as unknown as Observable<
      DeliveryPhoto[]
    >;
  }

  private queryPhotosByDeliveryId(deliveryId: number): Promise<DeliveryPhoto[]> {
    return this.deliveryPhotoTable.where({ deliveryId }).toArray();
  }

  async addLocallyFinishedDelivery(processedDelivery: ProcessedDelivery) {
    // TODO: if dexie cant save, an error that is shown on the UI to the user is necessary! there must be completed data!
    // -> FEHLER anzeigen, und ggf share button "SEND DATA VIA MAIL / WHATSAPP"

    console.log('addLocallyFinishedDelivery - delivery', processedDelivery);
    if (!processedDelivery.deliveryId) {
      console.error('Delivery ID is required to add a finished delivery.');
      return;
    }
    try {
      // Check if the delivery ID already exists in the processedDeliveriesTable to avoid duplication
      const existingEntry = await this.processedDeliveriesTable.get({
        deliveryId: processedDelivery.deliveryId,
      });

      if (existingEntry) {
        console.error(`Processed delivery for ID ${processedDelivery.deliveryId} already exists.`);
        return;
      }

      // Add the processed delivery to the processedDeliveriesTable
      const id = await this.processedDeliveriesTable.add(processedDelivery);

      // Setting IDriverDelivery.isDelivered flag to TRUE, in order to mark the delivery as delivered on the tour page.
      // But remember: the delivery itself may still be in CREATED or READY_TO_DELIVER.
      // TODO/CHECK: what if delivery is no longer assigned to the driver in the meantime, and server deliveries where pulled in the bg (not yet possible)? can this deliveriesTable.update crash?
      await this.deliveriesTable.update(processedDelivery.deliveryId, {
        isDelivered: true,
      });

      console.log(`Processed delivery added with ID: ${id}`);
    } catch (error) {
      console.error('Error adding processed delivery:', error);
    }
  }

  async addCapturedPhoto(photo: DeliveryPhoto): Promise<number> {
    if (!photo.deliveryId || !photo.base64Data || !photo.syncStatus) {
      console.error('Missing required photo information.');
      return Promise.reject('Missing required photo information.');
    }

    try {
      const id = await this.deliveryPhotoTable.add(photo);
      console.log(`Photo added with ID: ${id}`);
      return id; // this will be automatically wrapped in a Promise.resolve
    } catch (error) {
      console.error('Error adding photo to delivery:', error);
      return Promise.reject(error);
    }
  }

  // TODO: RENAME: currently returns PENDING and FAILED
  // SYNC METHODS
  async getPendingProcessedDeliveries(): Promise<ProcessedDelivery[]> {
    return (
      this.processedDeliveriesTable
        .where('syncStatus')
        // TODO: check this: sync is started. Pending->InProgress. Sync runs. Same sync starts again for InProgress (2 running for same delivery at the same time). first errors => failed. this one will be tried to push infintely.
        // SOLUTION: if processedDelivery is in any state other than completed AND a server pull leads to that delivery being server-DELIVERED/COMPLETED -> set the processedDelivery to COMPLETED_ON_SERVER. or just COMPLETED?
        .anyOf(
          LocalDeliveryDataSyncStatus.InProgress,
          LocalDeliveryDataSyncStatus.Pending,
          LocalDeliveryDataSyncStatus.Failed,
        )
        .toArray()
    );
  }

  allProcessedDeliveries(): Promise<ProcessedDelivery[]> {
    return this.processedDeliveriesTable.toArray();
  }

  async getPhotoById(id: number): Promise<DeliveryPhoto | undefined> {
    return this.deliveryPhotoTable.get(id);
  }

  async get3PendingDeliveryPhotos(): Promise<DeliveryPhoto[]> {
    return this.deliveryPhotoTable
      .where('syncStatus')
      .anyOf(LocalDeliveryDataSyncStatus.Pending)
      .limit(3)
      .toArray();
  }

  async getFailedDeliveryPhotos(): Promise<DeliveryPhoto[]> {
    return this.deliveryPhotoTable
      .where('syncStatus')
      .anyOf(LocalDeliveryDataSyncStatus.Failed)
      .toArray();
  }

  async getSingleOldInProgressDeliveryPhotos(): Promise<DeliveryPhoto[]> {
    const threeMinutesAgo = dayjs().subtract(3, 'minute').toDate();
    return this.deliveryPhotoTable
      .where('syncStatus')
      .equals(LocalDeliveryDataSyncStatus.InProgress)
      .and((photo) => photo.timestamp < threeMinutesAgo)
      .limit(1)
      .toArray();
  }

  async updateDeliverySyncStatus(
    deliveryId: number,
    status: LocalDeliveryDataSyncStatus,
  ): Promise<void> {
    console.log(
      `updateDeliverySyncStatus (indexeddb). deliveryId: ${deliveryId} getting set to status: ${status}`,
    );
    try {
      const updatedCount = await this.processedDeliveriesTable.update(deliveryId, {
        syncStatus: status,
      });
      if (updatedCount === 1) {
        console.log(`Successfully updated delivery ${deliveryId} to status ${status}.`);
      } else {
        console.warn(`Delivery ${deliveryId} was not found or no update was needed.`);
      }
    } catch (error) {
      console.error(`Error updating delivery ${deliveryId} status to ${status}:`, error);
      throw error; // Rethrow if you want to handle this error in the calling context.
    }
  }

  // photo sync
  async updatePhotoSyncStatus(photoId: number, status: LocalDeliveryDataSyncStatus): Promise<void> {
    console.log(
      `updatePhotoSyncStatus (indexeddb). photoId: ${photoId} getting set to status: ${status}`,
    );
    try {
      const updatedCount = await this.deliveryPhotoTable.update(photoId, {
        syncStatus: status,
      });
      if (updatedCount === 1) {
        console.log(`Successfully updated photo ${photoId} to status ${status}.`);
      } else {
        console.warn(`Photo ${photoId} was not found or no update was needed.`);
      }
    } catch (error) {
      console.error(`Error updating photo ${photoId} status to ${status}:`, error);
      throw error; // Rethrow if you want to handle this error in the calling context.
    }
  }

  // < SYNC

  async cleanupLocalDb(): Promise<void> {
    const sixDaysAgo = dayjs().subtract(6, 'day').toDate();
    const fourteenDaysAgo = dayjs().subtract(14, 'day').toDate();
    try {
      // PHOTOS
      // Query for photos to delete: COMPLETED status and timestamp older than 7 days ago
      const photosToDelete = await this.deliveryPhotoTable
        .where('syncStatus')
        .equals(LocalDeliveryDataSyncStatus.Completed)
        .and((photo) => photo.timestamp < sixDaysAgo)
        .toArray();

      // Extract the ids of photos to delete
      const idsToDelete = photosToDelete.map((photo) => photo.id!);

      // Bulk delete by ids
      console.log('cleanupLocalDb will DELETE: - idsToDelete', idsToDelete);
      await this.deliveryPhotoTable.bulkDelete(idsToDelete);
      console.log(`Deleted ${idsToDelete.length} photos from deliveryPhotoTable.`);

      // ------------------------------
      // PROCESSED DELIVERIES
      // Query for processed deliveries to delete: COMPLETED status and timestamp older than 14 days ago
      const processedDeliveriesToDelete = await this.processedDeliveriesTable
        .where('syncStatus')
        .equals(LocalDeliveryDataSyncStatus.Completed)
        .and((processedDelivery) => processedDelivery.deliveredAtMarketDate < fourteenDaysAgo)
        .toArray();

      // Extract the ids of processed deliveries to delete
      const processedDelivsIdsToDelete = processedDeliveriesToDelete.map(
        (processedDeliv) => processedDeliv.deliveryId!,
      );

      // Bulk delete by ids
      console.log(
        'cleanupLocalDb will DELETE: - processedDelivsIdsToDelete',
        processedDelivsIdsToDelete,
      );
      await this.processedDeliveriesTable.bulkDelete(processedDelivsIdsToDelete);
      console.log(
        `Deleted ${processedDelivsIdsToDelete.length} ProcessedDeliveries from processedDeliveriesTable.`,
      );
    } catch (error) {
      console.error('Error cleaning up the local database:', error);
    }
  }

  async getInspectTableEntries() {
    try {
      const processedDeliveries = await this.processedDeliveriesTable.toArray().then((deliveries) =>
        deliveries.map(
          ({
             deliveryId,
             deliveryProducts,
             signatureName,
             signatureImage,
             deliveredAtMarketDate,
             messageFromMarket,
             syncStatus,
           }) => ({
            syncStatus,
            deliveryId,
            deliveryProducts: deliveryProducts.map((dp: any) => ({
              id: dp.id,
              actualQuantity: dp.actualQuantity,
              returnQuantity: dp.returnQuantity,
              targetQuantity: dp.targetQuantity,
              productId: dp.product.id,
              productNo: dp.product.productNo
            })),
            deliveryProductsLength: deliveryProducts.length,
            signatureName,
            signatureImageLength: signatureImage.length,
            messageFromMarket,
            deliveredAtMarketDate,
          }),
        ),
      );

      const deliveries = await this.deliveriesTable.toArray().then((d) =>
        d.map(({ id, marketId, deliverySlipNumber, deliveryProducts, deliveryDate, status }) => ({
          id,
          status,
          marketId,
          deliverySlipNumber,
          deliveryDate,
          deliveryProductsLength: deliveryProducts.length,
          deliveryProducts: deliveryProducts.map((dp: any) => ({
            id: dp.id,
            actualQuantity: dp.actualQuantity,
            returnQuantity: dp.returnQuantity,
            targetQuantity: dp.targetQuantity,
            productId: dp.product.id,
            productNo: dp.product.productNo
          })),
        })),
      );

      const vehicles = await this.vehiclesTable.toArray().then((v) =>
        v.map(({ id, licensePlate }) => ({
          id,
          licensePlate,
        })),
      );

      const photos = await this.deliveryPhotoTable.toArray().then((p) =>
        p.map(({ id, deliveryId, base64Data, timestamp, syncStatus }) => ({
          syncStatus,
          id,
          deliveryId,
          base64DataLength: base64Data.length, // replace image with its length
          timestamp,
        })),
      );

      return {
        processedDeliveries,
        photos,
        deliveries,
        vehicles,
      };
    } catch (e) {
      console.error('getInspectTableEntries - e', e);
      return {
        inspectError: {
          tables: this.tables ? this.tables.map((t) => t.name) : 'no tables',
        },
      };
    }
  }

  private deleteDbIfExists() {
    Dexie.exists(DEXIE_DB_NAME)
      .then((exists) => {
        if (exists) {
          const db = new Dexie(DEXIE_DB_NAME);
          db.delete()
            .then(() => {
              console.log('Database successfully deleted');
            })
            .catch((error) => {
              console.error('Could not delete database', error);
            });
        } else {
          console.log('Database does not exist, no deletion necessary.');
        }
      })
      .catch((error) => {
        console.error('Error checking database existence', error);
      });
  }

  // MANUAL DELIVERY FIXES

  // used on 2024-05-16 to fix delivery from driver David with slipNo: 1 02 0105 0022
  async updateSpecificDeliveryProduct() {
    const deliveryId = 3202;
    const targetDeliveryProductId = 142641;
    const targetProductId = 12;

    try {
      const delivery = await this.processedDeliveriesTable.get({ deliveryId });
      if (!delivery) {
        console.error(`No processed delivery found with ID ${deliveryId}`);
        return;
      }

      // already fixed
      if (delivery.syncStatus === 'COMPLETED') {
        console.warn('updateSpecificDeliveryProduct - delivery already fixed - return early');
        return;
      }

      const productToUpdate = delivery.deliveryProducts.find(
        (deliveryProduct: any) =>
          deliveryProduct.id === targetDeliveryProductId &&
          deliveryProduct.product.id === targetProductId,
      );

      if (!productToUpdate) {
        console.error('No product found with the specified id and productId');
        return;
      }

      // Update the returnQuantity to 0
      (productToUpdate as any).returnQuantity = 0;

      // Save the updated processed delivery back to the database
      await this.processedDeliveriesTable.put(delivery);
      console.log(
        `Updated product with subId ${targetDeliveryProductId} in deliveryId ${deliveryId}`,
      );
    } catch (error) {
      console.error('Error updating the specific delivery product:', error);
    }
  }
}

// TODO: check row<id, entire js object>, vs <id, COLUMNS[]>
// TODO: MIGRATIONS / updates on schema change


// offline first article/tut: https://techtalks.qima.com/develop-in-offline-an-offline-adventure/
// DEXIE EXAMPLE CLASSES https://dev.to/andyhaskell/using-dexie-js-to-write-slick-indexeddb-code-304o
// Relationships with dexie: https://fireflysemantics.medium.com/one-to-many-relationships-with-dexie-48449c50d6b3
// https://offering.solutions/blog/articles/2018/11/21/online-and-offline-sync-with-angular-and-indexeddb/
// https://medium.com/@bvjebin/yours-insanely-offline-first-3b946e526cc1

// https://techtalks.qima.com/develop-in-offline-an-offline-adventure/
// use angular interceptors to catch request? Interceptors allow us to intercept the request from services and act based on the network state. We use an Angular interceptor to intercept and execute the HTTP requests.
// via https://techtalks.qima.com/develop-in-offline-an-offline-adventure/


// TODO: Check for where clause queries, in order to filter directly via "db"
// EXAMPLE:
//   export class FriendsComponent {
//     friends$ = liveQuery(() => listFriends());
//   }
//
//   async function listFriends() {
//     //
//     // Query the DB using our promise based API.
//     // The end result will magically become
//     // observable.
//     //
//     return await db.friends
//       .where("age")
//       .between(18, 65)
//       .toArray();
//   }

// async resetDatabase() {
//   await db.transaction('rw', 'vehicles', () => {
//     this.vehicles.clear();
//     // this.populate();
//   });
// }
