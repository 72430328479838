<ng-container *ngIf="item">
  <div class="numpad-wp mt-3">
    <div class="display-area" style="display: flex; justify-content: center; align-items: center;">
      <span class="pl-1" style="overflow: hidden; text-overflow: ellipsis; flex-grow: 1;">
        <b>{{ item.product.productNo }}</b> • {{ item.product.description }}</span>
      <div class="ml-1" style="white-space: nowrap; text-transform: uppercase; font-weight: bold;">
        <ng-container *ngIf="quantityType === 'actualQuantity'">
          Menge
        </ng-container>
        <ng-container *ngIf="quantityType === 'returnQuantity'">
          Retoure
        </ng-container>
      </div>
    </div>

    <!--                  TODO: no input, readonly label-->
    <prf-input class="input-area"
               [(ngModel)]="item[quantityType]"
               [disabled]="true"
    ></prf-input>

    <ion-button class="one" (click)="onClickNumber(1)">1</ion-button>
    <ion-button class="two" (click)="onClickNumber(2)">2</ion-button>
    <ion-button class="three" (click)="onClickNumber(3)">3</ion-button>
    <ion-button class="four" (click)="onClickNumber(4)">4</ion-button>
    <ion-button class="five" (click)="onClickNumber(5)">5</ion-button>
    <ion-button class="six" (click)="onClickNumber(6)">6</ion-button>
    <ion-button class="seven" (click)="onClickNumber(7)">7</ion-button>
    <ion-button class="eight" (click)="onClickNumber(8)">8</ion-button>
    <ion-button class="nine" (click)="onClickNumber(9)">9</ion-button>
    <ion-button class="zero" (click)="onClickNumber(0)">0</ion-button>


    <ion-button class="clear" (click)="onClickNumber(null)">
      <ion-icon name="close-outline"></ion-icon>
    </ion-button>
    <ion-button class="confirm" color="success" (click)="onClickConfirm()">
      <ion-icon name="swap-horizontal-outline"></ion-icon>
    </ion-button>

    <!--    Vertikal runter, d.h. wenn in Menge => zur Menge des nächsten Produkts. Wenn in Retoure => zur Ret. des nächst. Produkts -->
    <ion-button class="confirm-next" color="success" (click)="onClickNext()">
      <ion-icon slot="end" name="arrow-down-outline"></ion-icon>
      WEITER
    </ion-button>

    <ion-button class="plus" (click)="onClickPlus()">+</ion-button>
    <ion-button class="minus" (click)="onClickMinus()">-</ion-button>

    <ion-button fill="outline"
                class="minimize"
                (click)="onClickMinimize()">
      <ion-icon name="chevron-down"></ion-icon>
    </ion-button>

    <div class="space"></div>
    <div class="empty"></div>
  </div>
</ng-container>
