import { IEntity } from './entity.interface';

export enum EUserRole {
  ADMIN = 'ADMIN',
  MANAGER = 'MANAGER',
  DRIVER = 'DRIVER',
}

export enum EUserStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export interface IUser extends IEntity {
  username: string;
  firstName: string;
  lastName: string;
  password?: string;
  role: EUserRole;
  status: EUserStatus;
}
