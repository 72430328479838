import { CommonModule } from '@angular/common';
import SignaturePad, { Options, PointGroup } from 'signature_pad';
import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';

export interface NgSignaturePadOptions extends Options {
  canvasHeight: number;
  canvasWidth: number;
}

export interface SignaturePadDrawEndEvent {
  event: MouseEvent | Touch;
  dataURL: string;
}

@Component({
  selector: 'prf-signature-pad',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './signature-pad.component.html',
  styleUrls: ['./signature-pad.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SignaturePadComponent implements OnDestroy, AfterContentInit {
  @Input({ required: true }) public options: NgSignaturePadOptions;
  @Input() public personName: string = '';
  @Input({ required: true }) public canvasHeight!: number;
  @Input() public rotateOutputByDeg!: number;

  @Output() public drawStart: EventEmitter<MouseEvent | Touch>;
  @Output() public drawBeforeUpdate: EventEmitter<MouseEvent | Touch>;
  @Output() public drawAfterUpdate: EventEmitter<MouseEvent | Touch>;
  @Output() public drawEnd: EventEmitter<SignaturePadDrawEndEvent>;

  private signaturePad!: SignaturePad;

  constructor(private elementRef: ElementRef) {
    this.options = {} as NgSignaturePadOptions;
    this.drawStart = new EventEmitter<MouseEvent | Touch>();
    this.drawBeforeUpdate = new EventEmitter<MouseEvent | Touch>();
    this.drawAfterUpdate = new EventEmitter<MouseEvent | Touch>();
    this.drawEnd = new EventEmitter<SignaturePadDrawEndEvent>();
  }

  public ngAfterContentInit(): void {
    const canvas: HTMLCanvasElement = this.getCanvas();
    if (this.options.canvasHeight) {
      canvas.height = this.options.canvasHeight;
    }

    if (this.options.canvasWidth) {
      canvas.width = this.options.canvasWidth;
    }

    this.signaturePad = new SignaturePad(canvas, this.options);
    (this.signaturePad as any).addEventListener('beginStroke', (event: CustomEvent) =>
      this.beginStroke(event.detail),
    );
    (this.signaturePad as any).addEventListener('beforeUpdateStroke', (event: CustomEvent) =>
      this.beforeUpdateStroke(event.detail),
    );
    (this.signaturePad as any).addEventListener('afterUpdateStroke', (event: CustomEvent) =>
      this.afterUpdateStroke(event.detail),
    );
    (this.signaturePad as any).addEventListener('endStroke', (event: CustomEvent) =>
      this.endStroke(event.detail),
    );
  }

  public ngOnDestroy(): void {
    const canvas: HTMLCanvasElement = this.getCanvas();
    canvas.width = 0;
    canvas.height = 0;
  }

  /**
   * Redraw or Resize canvas, note this will clear data.
   */
  public redrawCanvas(): void {
    const canvas: HTMLCanvasElement = this.getCanvas();
    // when zoomed out to less than 100%, for some very strange reason,
    // some browsers report devicePixelRatio as less than 1, and only part of the canvas is cleared then.
    const ratio: number = Math.max(window.devicePixelRatio || 1, 1);
    canvas.width = canvas.offsetWidth * ratio;
    canvas.height = canvas.offsetHeight * ratio;
    (canvas as any).getContext('2d').scale(ratio, ratio);
    this.signaturePad.clear(); // otherwise isEmpty() might return incorrect value
  }

  /**
   * Returns signature image as an array of point groups
   */
  public toData(): PointGroup[] {
    if (this.signaturePad) {
      return this.signaturePad.toData();
    } else {
      return [];
    }
  }

  /**
   * Draws signature image from an array of point groups
   */
  public fromData(points: Array<PointGroup>): void {
    this.signaturePad.fromData(points);
  }

  /**
   * Returns signature image as data URL (see https://mdn.io/todataurl for the list of possible parameters)
   */
  public toDataURL(imageType?: string, quality?: number): string {
    return this.signaturePad.toDataURL(imageType, quality); // save image as data URL
  }

  /**
   * Clears the canvas
   */
  public clear(): void {
    this.signaturePad.clear();
  }

  /**
   * Returns true if canvas is empty, otherwise returns false
   */
  public isEmpty(): boolean {
    return this.signaturePad.isEmpty();
  }

  /**
   * Unbinds all event handlers
   */
  public off(): void {
    this.signaturePad.off();
  }

  /**
   * Rebinds all event handlers
   */
  public on(): void {
    this.signaturePad.on();
  }

  public set(option: string, value: any): void {
    const canvas: HTMLCanvasElement = this.getCanvas();
    switch (option) {
      case 'canvasHeight':
        canvas.height = value;
        break;
      case 'canvasWidth':
        canvas.width = value;
        break;
      default:
        (this.signaturePad as any)[option] = value;
    }
  }

  public beginStroke(event: MouseEvent | Touch): void {
    this.drawStart.emit(event);
  }

  public beforeUpdateStroke(event: MouseEvent | Touch): void {
    this.drawBeforeUpdate.emit(event);
  }

  public afterUpdateStroke(event: MouseEvent | Touch): void {
    this.drawAfterUpdate.emit(event);
  }

  public endStroke(event: MouseEvent | Touch): void {
    if (this.rotateOutputByDeg) {
      this.rotateImage(this.trimCanvas().toDataURL(), this.rotateOutputByDeg, (rotatedDataURL) => {
        this.drawEnd.emit({ event, dataURL: rotatedDataURL });
      });
    } else {
      this.drawEnd.emit({ event, dataURL: this.trimCanvas().toDataURL() });
    }
  }

  private rotateImage(
    srcBase64: string,
    degrees: number,
    callback: (resultBase64: string) => void,
  ): void {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d')!;
    const image = new Image();

    image.onload = () => {
      canvas.width = degrees % 180 === 0 ? image.width : image.height;
      canvas.height = degrees % 180 === 0 ? image.height : image.width;

      ctx.translate(canvas.width / 2, canvas.height / 2);
      ctx.rotate((degrees * Math.PI) / 180);
      ctx.drawImage(image, -image.width / 2, -image.height / 2);

      callback(canvas.toDataURL());
    };

    image.src = srcBase64;
  }

  public getSignaturePad(): SignaturePad {
    return this.signaturePad;
  }

  public getCanvas(): HTMLCanvasElement {
    return this.elementRef.nativeElement.querySelector('canvas');
  }

  private trimCanvas() {
    const canvas = this.getCanvas();
    const ctx = canvas.getContext('2d')!;
    const copy = document.createElement('canvas').getContext('2d')!;
    const pixels = ctx.getImageData(0, 0, canvas.width, canvas.height);
    const l = pixels.data.length;
    let i;
    const bound: any = { top: null, left: null, right: null, bottom: null };
    let x;
    let y;
    for (i = 0; i < l; i += 4) {
      if (pixels.data[i + 3] !== 0) {
        x = (i / 4) % canvas.width;
        // tslint:disable-next-line: no-bitwise
        y = ~~((i / 4) / canvas.width);
        if (bound.top === null) {
          bound.top = y;
        }
        if (bound.left === null) {
          bound.left = x;
        } else if (x < bound.left) {
          bound.left = x;
        }
        if (bound.right === null) {
          bound.right = x;
        } else if (bound.right < x) {
          bound.right = x;
        }
        if (bound.bottom === null) {
          bound.bottom = y;
        } else if (bound.bottom < y) {
          bound.bottom = y;
        }
      }
    }
    // const trimMargin = this._options.trimMargin ? this._options.trimMargin : 0;
    const trimMargin = 0;
    const trimHeight = bound.bottom - bound.top + (trimMargin * 2);
    const trimWidth = bound.right - bound.left + (trimMargin * 2);
    const trimmed = ctx.getImageData(bound.left, bound.top, trimWidth, trimHeight);
    copy.canvas.width = trimWidth;
    copy.canvas.height = trimHeight;
    copy.putImageData(trimmed, trimMargin, trimMargin);
    return copy.canvas;
  }
}

// CODE TO ROTATE ONCE IN BROWSER CONSOLE
// function rotateBase64Image(dataUrl) {
//   const degrees = -90; // Internal rotation degree
//
//   return new Promise((resolve, reject) => {
//     const image = new Image();
//     image.onload = () => {
//       const canvas = document.createElement('canvas');
//       const ctx = canvas.getContext('2d');
//
//       canvas.width = degrees % 180 === 0 ? image.width : image.height;
//       canvas.height = degrees % 180 === 0 ? image.height : image.width;
//
//       ctx.translate(canvas.width / 2, canvas.height / 2);
//       ctx.rotate(degrees * Math.PI / 180);
//       ctx.drawImage(image, -image.width / 2, -image.height / 2);
//
//       resolve(canvas.toDataURL());
//     };
//     image.onerror = reject;
//     image.src = dataUrl;
//   });
// }
//
// let INPUT_BASE64 = 'yourBase64DataUrl';
// // Example usage with a base64 data URL (you'll need to replace 'yourBase64DataUrl' with an actual base64 image string)
// rotateBase64Image(INPUT_BASE64).then(rotatedImageUrl => {
//   console.log(rotatedImageUrl);
//   // You can also append the result to the document body to view the image:
//   const img = document.createElement('img');
//   img.src = rotatedImageUrl;
//   document.body.appendChild(img);
// });
