<ion-modal #signaturePadModal
           [isOpen]="isOpen"
>
  <ng-template>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-button (click)="onClickDeleteSignature()">Löschen</ion-button>
        </ion-buttons>
        <ion-title>Unterschreiben</ion-title>
        <ion-buttons slot="end">
          <ion-button (click)="confirm()" [strong]="true">OK</ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding">
      <prf-signature-pad #signaturePad
                         [options]="signaturePadOptions"
                         [personName]="name"
                         [canvasHeight]="CANVAS_HEIGHT"
                         [rotateOutputByDeg]="-90"
                         (drawEnd)="onDrawEnd($event)" />
    </ion-content>
  </ng-template>
</ion-modal>
