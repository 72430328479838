<ion-header>
  <ion-toolbar color="dark">
    <ion-buttons slot="start">
      <ion-button (click)="onClickHome()">
        <ion-icon slot="start" name="home"></ion-icon>
      </ion-button>
    </ion-buttons>
    @if (shouldShowLogout) {
      <ion-buttons slot="end">
        <ion-button
          id="open-user-settings-action-sheet">
          <ion-icon name="person"></ion-icon>
        </ion-button>
      </ion-buttons>
    }
    <ion-title *ngIf="title" size="large">{{ title }}</ion-title>
    <ion-title *ngIf="subTitle" size="small"><span class="ion-text-nowrap">{{ subTitle }}</span></ion-title>
  </ion-toolbar>
</ion-header>

@if (shouldShowLogout) {
  <ion-action-sheet trigger="open-user-settings-action-sheet"
                    [header]="'Angemeldet als ' + currentUsername"
                    (didDismiss)="onUserSettingsActionSheetDismiss($event)"
                    [buttons]="userMenuActionSheetButtons" />
}
