import { IEntity } from './entity.interface';
import { IAddress } from "./address.interface";

export enum InvoiceStatus {
  CREATED = 'CREATED',
  SENT = 'SENT',
  PAID = 'PAID',
  CANCELLED = 'CANCELLED',
}

// Beleg/Rechnung um BEMERKUNG ergänzen. Dort können zB Stornogründe oÄ angegeben werden.
export interface IInvoice extends IEntity {
  deliveryId?: number; // ?-"workaround", ? is used because InvoiceEntity has no deliveryId, but only delivery. Proper typing solution needed here.
  deliverySlipNumber?: string;
  marketId?: number; // ?-"workaround", ? is used because InvoiceEntity has no marketId, but only market. Proper typing solution needed here.
  marketName?: string;
  marketDeliveryAddress?: IAddress;
  invoiceNumber: string;
  invoiceDate: Date;
  status: InvoiceStatus;
  netDiscount: number;
  invoiceEmailSent: boolean;
  // items: any[]; // IInvoiceItem[] ?
  // TODO: grossAmount? derived from delivery.deliveryProdocuts?
  // TODO: additional invoicePositions, ie additional costs/service, or discounts?
  invoiceAmount: number; // net / gross? -> ie for overview . derived sum for instant access
}
