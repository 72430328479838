import { inject, Injectable } from '@angular/core';
import { DeliveriesDataService } from '../../shared/services/data/deliveries-data.service';
import { map, Observable } from 'rxjs';
import { isToday } from './deliveries-week.page.component';
import { DeliveryTour, IDriverDelivery } from "@prf/shared/domain";

@Injectable({
  providedIn: 'root',
})
export class DeliveriesWeekPageService {
  private deliveriesDataService = inject(DeliveriesDataService);
  // TODO: EXTRACT mapping operator/fn into other
  deliveryTours$!: Observable<DeliveryTour[]>;

  constructor() {
    this.deliveryTours$ = this.deliveriesDataService.deliveries$.pipe(
      map((deliveries: IDriverDelivery[]) => {
        const grouped: { [key: string]: IDriverDelivery[] } = {};

        deliveries.forEach((delivery) => {
          const date = new Date(delivery.deliveryDate)
            .toISOString()
            .split('T')[0];
          if (!grouped[date]) {
            grouped[date] = [];
          }
          grouped[date].push(delivery);
        });

        const result: DeliveryTour[] = Object.keys(grouped).map((date) => {
          const deliveriesForDate = grouped[date];
          const uniqueMarkets = new Set(
            deliveriesForDate.map((d) => d.marketId),
          );

          return {
            date: date,
            numMarkets: uniqueMarkets.size,
            isToday: isToday(date),
            deliveries: deliveriesForDate,
          };
        });

        return result;
      }),
    );
  }

  refreshDeliveries() {
    return this.deliveriesDataService.pullServerDeliveriesForMyCurrentWeek();
  }

  checkForPendingPhotosToBeSynced() {
    return this.deliveriesDataService.checkForPendingPhotosToBeSynced();
  }

  // Currently not used.
  // checkForPendingDeliveriesToBeSynced() {
  //   return this.deliveriesDataService.checkForPendingDeliveriesToBeSynced();
  // }
}
